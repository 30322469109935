import { ReactElement } from "react";
import { Skeleton, Title } from "@mantine/core";

type HeaderProps = {
    dataId: string;
    title?: string;
};
export const Header = ({ dataId, title }: HeaderProps): ReactElement =>
    title ? (
        <Title order={1} data-id={dataId}>
            {title}
        </Title>
    ) : (
        <Skeleton width={100} height={40} />
    );
