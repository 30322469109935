export enum ButtonVariants {
    Filled = "filled",
    Outline = "outline",
    Subtle = "subtle",
}

export enum ButtonColors {
    Red = "flexidaoRed",
    Green = "flexidaoGreen",
}
