export const DEFAULT_HEIGHT = 100;
export const DONUT_THICKNESS_RATIO = 6.25;

export const OPACITY_ACTIVE = 1;
export const OPACITY_INACTIVE = 0.4;

export const SCALE_ACTIVE = "scale(1)";
export const SCALE_INACTIVE = "scale(0.95)";

export const TWO = 2;

export const LEGEND_ITEM_SKELETON_HEIGHT = 40;
export const LEGEND_ITEM_SKELETON_WIDTH = 200;
