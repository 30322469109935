import { ReactElement, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Box, Group, Skeleton, Text } from "@mantine/core";
import { flexidaoGrey } from "@flexidao/ui-lib";
import { DonutChartCenterElement, DonutChartItem } from "../types";
import {
    DEFAULT_HEIGHT,
    DONUT_THICKNESS_RATIO,
    OPACITY_ACTIVE,
    OPACITY_INACTIVE,
    SCALE_ACTIVE,
    SCALE_INACTIVE,
    TWO,
} from "../consts";
import { Legend } from "../legend/legend";
import { CenterElement } from "./center-element";
import { ComponentState } from "@flexidao/dto";

type DonutChartProps = {
    dataId: string;
    data: Array<DonutChartItem>;
    centerElement?: DonutChartCenterElement;
    height?: number;
    thickness?: number;
    legendPosition?: "right";
    state: ComponentState;
};

export const DonutChart = ({
    dataId,
    data,
    centerElement,
    legendPosition,
    height = DEFAULT_HEIGHT,
    thickness = height / DONUT_THICKNESS_RATIO,
    state,
}: DonutChartProps): ReactElement => {
    const innerRadius = height / TWO - thickness;
    const outerRadius = height / TWO;

    // Hover state
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    // If all the values are 0, we need to display a single grey slice that takes up the whole chart
    const isAllZero = data.every((item) => item.value === 0);
    const chartData = isAllZero
        ? [
              {
                  value: 1,
                  color: flexidaoGrey[2],
              },
          ]
        : data;

    const isActive = (index: number): boolean => activeIndex === null || activeIndex === index;

    const onMouseEnter = (index: number): void => {
        if (isAllZero) {
            return;
        }

        setActiveIndex(index);
    };

    const onMouseLeave = (): void => {
        if (isAllZero) {
            return;
        }

        setActiveIndex(null);
    };

    return (
        <Group align="center" spacing="32px" data-id={dataId} onMouseLeave={onMouseLeave}>
            {state === ComponentState.Success ? (
                <Box pos="relative" h={height} w={height} onMouseLeave={onMouseLeave}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart onMouseLeave={onMouseLeave}>
                            <Pie
                                data={chartData}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                startAngle={90}
                                endAngle={-270}
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                paddingAngle={0}
                                animationBegin={0}
                                animationDuration={800}
                                animationEasing="ease-out"
                                onMouseLeave={onMouseLeave}
                            >
                                {chartData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                        opacity={
                                            isActive(index) ? OPACITY_ACTIVE : OPACITY_INACTIVE
                                        }
                                        onMouseEnter={(): void => onMouseEnter(index)}
                                        onMouseLeave={onMouseLeave}
                                        style={{
                                            transform: isActive(index)
                                                ? SCALE_ACTIVE
                                                : SCALE_INACTIVE,
                                            transition: "all 0.3s ease",
                                            transformOrigin: "center",
                                        }}
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>

                    {centerElement && <CenterElement centerElement={centerElement} />}
                </Box>
            ) : state === ComponentState.Loading ? (
                <Skeleton h={height} w={height} />
            ) : (
                <Text size="xs" c="flexidaoRed">
                    Error
                </Text>
            )}

            {legendPosition === "right" && (
                <Legend
                    dataIdPrefix={dataId}
                    data={data}
                    isActive={isActive}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    state={state}
                />
            )}
        </Group>
    );
};
