import { ReactElement, ReactNode, useMemo } from "react";
import { BarStack } from "@visx/shape";
import { Group as VisxGroup } from "@visx/group";
import { AxisBottom, AxisRight } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { flexidaoGrey } from "@flexidao/ui-lib/mantine-theme/colors/flexidao-colors";
import { CHART_MARGIN, CHART_PADDING, NUM_X_TICKS, NUM_Y_TICKS, TOOLTIP_STYLES } from "./consts";
import {
    InternalStackedBarChartDataItem,
    StackedBarChartData,
    StackedBarChartSeries,
    TooltipData,
} from "../types";
import { formatDate, getXDomain, getYDomain, yTickFormatter } from "../utils";
import { ParentSize } from "@visx/responsive";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Group, Stack, Text } from "@mantine/core";
import { formatDateHour, formatNumber, labelToDataId, round } from "@flexidao/ui-lib";
import { localPoint } from "@visx/event";
import { TWO } from "../brush/consts";

type BarStackProps<T extends Record<string, number>> = {
    chartHeight: number;
    chartMargin?: { top: number; right: number; bottom: number; left: number };
    chartPadding?: { top: number; right: number; bottom: number; left: number };
    data: StackedBarChartData<T>;
    series: StackedBarChartSeries<T>;
    period: [Date, Date];
    children?: ReactNode;
    hideAxes?: boolean;
    disableTooltip?: boolean;
};

export const StackedBarChart = <T extends Record<string, number>>({
    chartHeight,
    chartMargin = CHART_MARGIN,
    chartPadding = CHART_PADDING,
    data,
    series,
    period,
    children,
    hideAxes = false,
    disableTooltip = false,
}: BarStackProps<T>): ReactElement => {
    const activeKeys = useMemo(
        () => series.filter(({ isSeriesActive }) => isSeriesActive).map(({ dataKey }) => dataKey),
        [series],
    );
    const activeColors = useMemo(
        () => series.filter(({ isSeriesActive }) => isSeriesActive).map(({ color }) => color),
        [series],
    );

    // ----- Scales and domains start -----
    const xDomain: Array<string> = useMemo(() => getXDomain(period), [period]);
    const xScale = useMemo(
        () =>
            scaleBand<string>({
                domain: xDomain,
                padding: 0.2,
            }),
        [period],
    );
    const yDomain: [number, number] = useMemo(
        () => getYDomain(data, activeKeys),
        [data, activeKeys],
    );
    const yScale = useMemo(
        () =>
            scaleLinear<number>({
                domain: yDomain,
                nice: true,
            }),
        [data, activeKeys],
    );
    const colorScale = useMemo(
        () =>
            scaleOrdinal<Extract<string, keyof T>, string>({
                domain: activeKeys,
                range: activeColors,
            }),
        [activeKeys, activeColors],
    );
    // ----- Scales and domains end -----

    // ----- Tooltip start -----
    const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<TooltipData<T>>();
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
        detectBounds: true,
    });
    // ----- Tooltip end -----

    // ----- Axes start -----
    const yTicks: Array<number> = useMemo(() => {
        const ticks: Array<number> = [...new Set(yScale.ticks(NUM_Y_TICKS).map(round))];

        if (ticks.length === 0) {
            ticks.push(yDomain[0]);

            if (yDomain[0] !== yDomain[1]) {
                ticks.push(yDomain[1]);
            }
        }

        return ticks;
    }, [yScale]);
    // ----- Axes end -----

    const chartData: Array<InternalStackedBarChartDataItem<T>> = useMemo(
        () =>
            data.map((item) => ({
                date: item.date,
                ...item.values,
            })),
        [data],
    );

    return (
        <div style={{ position: "relative", overflow: "visible" }}>
            <ParentSize style={{ width: "100%", height: chartHeight }}>
                {(parent): ReactElement => {
                    const chartWidth: number = parent.width - chartMargin.left - chartMargin.right;

                    // If chart width is 0 or lower, don't render the chart
                    if (chartWidth <= 0) {
                        return <></>;
                    }

                    xScale.range([chartPadding.left, chartWidth - chartPadding.right]);
                    yScale.range([
                        chartHeight - chartMargin.top - chartMargin.bottom - chartPadding.bottom,
                        chartPadding.top,
                    ]);

                    return (
                        <>
                            <svg
                                ref={containerRef}
                                width={chartWidth}
                                height={chartHeight}
                                style={{ overflow: "visible" }}
                            >
                                {/* ----- Axes start ----- */}
                                {!hideAxes && (
                                    <AxisRight
                                        tickValues={yTicks}
                                        tickFormat={yTickFormatter}
                                        scale={yScale}
                                        left={chartWidth - chartPadding.right}
                                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                        tickLabelProps={() => ({
                                            fontSize: 10,
                                            fontWeight: 400,
                                            fill: flexidaoGrey[7],
                                            textAnchor: "start",
                                            x: "12",
                                            dy: "3px",
                                        })}
                                        tickLineProps={{
                                            stroke: flexidaoGrey[3],
                                        }}
                                        tickLength={4}
                                        stroke={flexidaoGrey[3]}
                                    />
                                )}

                                {!hideAxes && (
                                    <AxisBottom
                                        numTicks={NUM_X_TICKS}
                                        scale={xScale}
                                        top={
                                            chartHeight +
                                            chartMargin.top -
                                            chartMargin.bottom -
                                            chartPadding.bottom
                                        }
                                        left={chartMargin.left}
                                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                        tickLabelProps={() => ({
                                            fontSize: 10,
                                            fontWeight: 400,
                                            fill: flexidaoGrey[7],
                                            textAnchor: "middle",
                                            y: "18",
                                        })}
                                        tickLineProps={{
                                            stroke: flexidaoGrey[3],
                                        }}
                                        tickLength={4}
                                        stroke={flexidaoGrey[3]}
                                    />
                                )}
                                {/* ----- Axes end ----- */}

                                <VisxGroup top={chartMargin.top} left={chartMargin.left}>
                                    {/* ----- Series start ----- */}
                                    <BarStack<{ date: Date } & T, Extract<string, keyof T>>
                                        data={chartData}
                                        keys={activeKeys}
                                        x={formatDate}
                                        y0={(d): number => {
                                            const value: number = d[0];

                                            if (value == null || isNaN(value)) {
                                                return 0;
                                            }

                                            return value;
                                        }}
                                        y1={(d): number => {
                                            const value: number = d[1];

                                            if (value == null || isNaN(value)) {
                                                return 0;
                                            }

                                            return value;
                                        }}
                                        xScale={xScale}
                                        yScale={yScale}
                                        color={colorScale}
                                    >
                                        {(barStacks): Array<Array<ReactElement>> =>
                                            barStacks.map(
                                                (barStack): Array<ReactElement> =>
                                                    barStack.bars.map(
                                                        (bar): ReactElement => (
                                                            <rect
                                                                key={`bar-stack-${barStack.index}-${bar.index}`}
                                                                x={bar.x}
                                                                y={bar.y}
                                                                height={bar.height}
                                                                width={bar.width}
                                                                fill={bar.color}
                                                                onMouseLeave={
                                                                    disableTooltip
                                                                        ? undefined
                                                                        : hideTooltip
                                                                }
                                                                onMouseMove={
                                                                    disableTooltip
                                                                        ? undefined
                                                                        : (event): void => {
                                                                              const eventSvgCoords =
                                                                                  localPoint(event);
                                                                              // eslint-disable-next-line no-magic-numbers
                                                                              const left =
                                                                                  bar.x +
                                                                                  bar.width / TWO;
                                                                              showTooltip({
                                                                                  tooltipData: {
                                                                                      ...bar.bar
                                                                                          .data,
                                                                                      color: bar.color,
                                                                                      dataKey:
                                                                                          bar.key,
                                                                                  },
                                                                                  tooltipTop:
                                                                                      eventSvgCoords?.y,
                                                                                  tooltipLeft: left,
                                                                              });
                                                                          }
                                                                }
                                                            />
                                                        ),
                                                    ),
                                            )
                                        }
                                        {/* ----- Series end ----- */}
                                    </BarStack>

                                    {/* ----- Children start ----- */}
                                    {children}
                                    {/* ----- Children end ----- */}
                                </VisxGroup>
                            </svg>

                            {/* ----- Tooltip start ----- */}
                            {!disableTooltip && tooltipOpen && tooltipData && (
                                <TooltipInPortal
                                    top={tooltipTop}
                                    left={tooltipLeft}
                                    style={TOOLTIP_STYLES}
                                >
                                    <Stack
                                        spacing="xs"
                                        data-id={labelToDataId({
                                            label: "stacked-bar-chart",
                                            suffix: "tooltip",
                                        })}
                                    >
                                        <Group
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <Text weight="bold" size="sm" c="flexidaoGrey.5">
                                                Date:
                                            </Text>

                                            <Text weight="bold" size="sm">
                                                {formatDateHour(tooltipData.date, "Etc/UTC")}
                                            </Text>
                                        </Group>

                                        <Group
                                            spacing="16px"
                                            noWrap
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <Group spacing={4}>
                                                <svg width={12} height={12}>
                                                    <circle
                                                        fill={tooltipData.color}
                                                        r={6}
                                                        cx={6}
                                                        cy={6}
                                                    />
                                                </svg>

                                                <Text size="sm">{tooltipData.dataKey}:</Text>
                                            </Group>

                                            <Text size="sm" fw="bold">
                                                {formatNumber(
                                                    tooltipData[tooltipData.dataKey] ?? 0,
                                                )}
                                            </Text>
                                        </Group>
                                    </Stack>
                                </TooltipInPortal>
                            )}
                            {/* ----- Tooltip end ----- */}
                        </>
                    );
                }}
            </ParentSize>
        </div>
    );
};
