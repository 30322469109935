import { formatDateMonth, IconCalendar, labelToDataId, MONTHS_IN_YEAR } from "@flexidao/ui-lib";
import {
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { ReactElement, useMemo, useState } from "react";
import { MonthRangeActions } from "./sections/month-range-actions";
import { MonthRangeCalendar } from "./sections/month-range-calendar";
import { MonthRangeHeader } from "./sections/month-range-header";
import { MonthRangePresets } from "./sections/month-range-presets";
import { MonthRangeOption } from "./types";
import { getDatePeriodFromMonthPeriodOption, getDatePeriodOptionFromMonthPeriod } from "./utils";
import { DateTime } from "luxon";

type MonthRangeFilterProps = {
    title: string;
    initialMonthRange: [Date, Date];
    handleApply: (monthRange: [Date, Date]) => void;
    dataId?: string;
    disabled?: boolean;
    maxRangeInMonths?: number;
    monthRangeOptions?: Array<MonthRangeOption>;
};

const formatMonth = (yearMonth: Date | null): string => {
    if (!yearMonth) {
        return formatDateMonth(new Date());
    }

    return formatDateMonth(yearMonth);
};

/**
 * Proxy initialMonthRange to UTC, for Mantine monthly picker and other related components
 *
 * @example
 *
 * Selected mantine monthly picker range: [Jan 2024, Dec 2024]
 * Internal initialMonthRange in Local time: [Dec 2023, Nov 2024]
 * Converted to UTC time: [Jan 2024, Dec 2024]
 *
 * @param initialMonthRange Local range
 * @returns Selected month range in UTC
 */
const decodeInputRange = ([startMonth, endMonth]: [Date, Date]): [Date, Date] => {
    const shiftedEndMonth = DateTime.fromJSDate(endMonth).minus({ month: 1 }).toJSDate();

    return [
        new Date(startMonth.getUTCFullYear(), startMonth.getUTCMonth()),
        new Date(shiftedEndMonth.getUTCFullYear(), shiftedEndMonth.getUTCMonth()),
    ];
};

const encodeOutputRange = ([startMonth, endMonth]: [Date, Date]): [Date, Date] => {
    const shiftedEndMonth = DateTime.fromJSDate(endMonth).plus({ month: 1 }).toJSDate();

    return [
        new Date(Date.UTC(startMonth.getFullYear(), startMonth.getMonth())),
        new Date(Date.UTC(shiftedEndMonth.getFullYear(), shiftedEndMonth.getMonth())),
    ];
};

export const MonthRangeFilter = ({
    title,
    initialMonthRange,
    handleApply,
    dataId = "month-range-picker",
    disabled = false,
    maxRangeInMonths = MONTHS_IN_YEAR,
    monthRangeOptions = [MonthRangeOption.LastYear, MonthRangeOption.YearToDate],
}: MonthRangeFilterProps): ReactElement => {
    const initialMonthRangeUtc = decodeInputRange(initialMonthRange);
    const initialMonthRangeOption: MonthRangeOption = useMemo(
        () => getDatePeriodOptionFromMonthPeriod(initialMonthRangeUtc),
        [initialMonthRangeUtc],
    );

    // Local state for the month range picker:
    //   - monthPickerFilterOpen is a boolean that indicates if the month range picker is open
    //   - selectedMonthRange is the month range selected by the user
    //   - selectedMonthRangeOption is the month range option selected by the user
    const [monthPickerFilterOpen, setMonthPickerFilterOpen] = useState<boolean>(false);
    const [selectedMonthRange, setSelectedMonthRange] =
        useState<[Date | null, Date | null]>(initialMonthRangeUtc);
    const [selectedMonthRangeOption, setSelectedMothRangeOption] =
        useState<MonthRangeOption>(initialMonthRangeOption);

    // If the selected period changes, update the selected month range option
    const handleSelectedDateRangeChange = (newMonthRange: [Date | null, Date | null]): void => {
        setSelectedMonthRange(newMonthRange);
        setSelectedMothRangeOption(getDatePeriodOptionFromMonthPeriod(newMonthRange));
    };

    // If the selected month range option changes, update the selected month range
    const handleDateRangeOptionSelected = (newMonthRangeOption: MonthRangeOption): void => {
        setSelectedMothRangeOption(newMonthRangeOption);

        if (newMonthRangeOption !== MonthRangeOption.Custom) {
            const newMonthRange: [Date, Date] =
                getDatePeriodFromMonthPeriodOption(newMonthRangeOption);
            setSelectedMonthRange(newMonthRange);
        }
    };

    const handleMonthPickerChange = (opened: boolean): void => {
        setSelectedMonthRange(initialMonthRangeUtc);

        setSelectedMothRangeOption(getDatePeriodOptionFromMonthPeriod(initialMonthRangeUtc));

        setMonthPickerFilterOpen(opened);
    };

    const handleMonthApplied = (): void => {
        if (selectedMonthRange[0] === null || selectedMonthRange[1] === null) {
            return;
        }

        // Reverse the UTC time to local time for the month picker component
        const correctedRange = encodeOutputRange([selectedMonthRange[0], selectedMonthRange[1]]);
        handleApply(correctedRange);
        setMonthPickerFilterOpen(!monthPickerFilterOpen);
    };

    const handleMonthCleared = (): void => {
        setSelectedMonthRange([null, null]);
        setSelectedMothRangeOption(MonthRangeOption.Custom);
    };

    return (
        <Popover
            opened={monthPickerFilterOpen}
            position="bottom-start"
            onChange={handleMonthPickerChange}
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  setMonthPickerFilterOpen(!monthPickerFilterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "target",
                    })}
                >
                    <Group>
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text fz="12px" lh="14px" size="sm" c="flexidaoGrey.8">
                                {formatMonth(initialMonthRangeUtc[0])}&nbsp;-&nbsp;
                                {formatMonth(initialMonthRangeUtc[1])}&nbsp;
                            </Text>
                        </Stack>

                        <IconCalendar size={16} />
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={(): CSSObject => ({
                        width: "fit-content",
                    })}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "dropdown",
                    })}
                >
                    <MonthRangeHeader selectedMonthRange={selectedMonthRange} dataId={dataId} />

                    <Divider />

                    <Group
                        align="flex-start"
                        sx={{
                            gap: "0",
                            columnGap: "0",
                        }}
                    >
                        <MonthRangePresets
                            selectedMonthRange={selectedMonthRange}
                            monthRangeOptions={monthRangeOptions}
                            selectedMonthRangeOption={selectedMonthRangeOption}
                            handleMonthRangeOptionSelected={handleDateRangeOptionSelected}
                        />

                        <Divider orientation="vertical" />

                        <MonthRangeCalendar
                            selectedMonthRange={selectedMonthRange}
                            maxRangeInMonths={maxRangeInMonths}
                            handleSelectedMonthRangeChange={handleSelectedDateRangeChange}
                        />
                    </Group>

                    <Divider />

                    <MonthRangeActions
                        selectedMonthRange={selectedMonthRange}
                        initialMonthRange={initialMonthRangeUtc}
                        handleMonthCleared={handleMonthCleared}
                        handleMonthApplied={handleMonthApplied}
                    />
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
