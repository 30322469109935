import { labelToDataId } from "@flexidao/ui-lib";
import { Divider, Group, Paper, Stack, Text } from "@mantine/core";
import { ReactElement, ReactNode } from "react";
import { SectionTitle, SectionTitleProps } from "./section-title";

// eslint-disable-next-line no-magic-numbers
type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type Unit = "px" | "em" | "rem" | "%";
type PaddingWithUnit = `${Digit}${number | ""}${Unit}` & `${number | ""}${Digit}${Unit}`;

type SectionProps = {
    title: SectionTitleProps;
    description?: string;
    hasDivider?: boolean;
    noSpaceAfterDivider?: boolean;
    actions?: ReactElement;
    children: ReactNode;
    dataId?: string;
    pb?: number | "xs" | "sm" | "md" | "lg" | "xl" | PaddingWithUnit;
};

export const Section = ({
    title,
    description,
    hasDivider = false,
    noSpaceAfterDivider = true,
    actions,
    children,
    dataId: dataId_,
    pb = "md",
}: SectionProps): ReactElement => {
    const dataId: string = dataId_ ?? labelToDataId({ prefix: "section", label: title.label });

    // eslint-disable-next-line no-magic-numbers
    const spacingAfterDivider: number = noSpaceAfterDivider ? 0 : 8;

    return (
        <Paper pt="md" px={0} pb={pb} data-id={dataId}>
            <Stack spacing={8}>
                <Group position="apart" px="xl" noWrap>
                    <Stack spacing={0}>
                        <SectionTitle
                            dataId={labelToDataId({ prefix: dataId, label: "title" })}
                            label={title.label}
                            tooltip={title.tooltip}
                        />

                        {description && (
                            <Text
                                data-id={labelToDataId({
                                    prefix: dataId,
                                    label: "description",
                                })}
                            >
                                {description}
                            </Text>
                        )}
                    </Stack>

                    {actions && (
                        <div data-id={labelToDataId({ prefix: dataId, label: "actions" })}>
                            {actions}
                        </div>
                    )}
                </Group>

                <Stack spacing={spacingAfterDivider}>
                    {hasDivider && (
                        <Divider data-id={labelToDataId({ prefix: dataId, label: "divider" })} />
                    )}

                    <div
                        data-id={labelToDataId({ prefix: dataId, label: "body" })}
                        style={{
                            position: "unset",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {children}
                    </div>
                </Stack>
            </Stack>
        </Paper>
    );
};
