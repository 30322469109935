import { DateTime } from "luxon";
export const formatYear = (date: Date, timeZone?: string): string =>
    date.toLocaleDateString("en-GB", {
        year: "numeric",
        timeZone,
    });

export const formatMonth = (date: Date, timeZone?: string): string =>
    date
        .toLocaleDateString("en-GB", {
            month: "short",
            year: "numeric",
            timeZone,
        })
        .replace("Sept", "Sep");

export const formatDate = (date: Date, timeZone?: string): string =>
    date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            timeZone,
        })
        .replace("Sept", "Sep");

export const formatDateMonthYear = (date: Date): string =>
    date
        .toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
        })
        .replace("Sept", "Sep");

export const formatDateMonth = (date: Date, timeZone?: string): string =>
    date
        .toLocaleDateString("en-GB", {
            month: "short",
            year: "numeric",
            timeZone,
        })
        .replace("Sept", "Sep");

export const formatDateTime = (date: Date, timeZone?: string): string =>
    `${date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone,
        })
        .replace("Sept", "Sep")} h`;

export const formatDateHourMinute = (date: Date, timeZone?: string): string =>
    `${date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone,
        })
        .replace("Sept", "Sep")} h`;

export const formatDateHour = (date: Date, timeZone?: string): string =>
    `${date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            timeZone,
        })
        .replace("Sept", "Sep")} h`;

export const formatYearOrDash = (date: Date | null, timeZone?: string): string => {
    if (!date) {
        return "-";
    }

    return formatYear(date, timeZone);
};

export const formatDateMonthYearOrUnknown = (date: Date | null, timeZone?: string): string => {
    if (!date) {
        return "Unknown";
    }

    return formatDate(date, timeZone);
};

/**
 * @description Formats a date to a local date string.
 *
 * @param date The date to format.
 * @param timezone The timezone to use.
 * @param shouldSubtractOneDay Whether to subtract one day from the date.
 *
 * @returns The formatted date string.
 */
export const formatLocalDate = ({
    date,
    timezone,
    shouldSubtractOneDay = false,
}: {
    date: Date;
    timezone: string;
    shouldSubtractOneDay?: boolean;
}): string => {
    const dateTime = shouldSubtractOneDay
        ? DateTime.fromJSDate(date).minus({ days: 1 }).toJSDate()
        : date;

    return formatDate(dateTime, timezone);
};
