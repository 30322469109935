import { MRT_ColumnDef } from "mantine-react-table";
import { Column } from "../types";
import { HeaderWithTooltip } from "../../headers/header-with-tooltip";
import { RowData } from "../../types";

export const addHeaderToColumn = <T extends RowData>(
    columnDef: MRT_ColumnDef<T>,
    column: Column<T>,
): MRT_ColumnDef<T> => ({
    ...columnDef,
    Header: () => <HeaderWithTooltip title={column.header} tooltip={column.headerTooltip} />,
});
