const addUnitsToFormattedValue = (formattedValue: string, units?: string): string => {
    return units ? `${formattedValue} ${units}` : formattedValue;
};

/**
 * This helper function is a replica of "format-number",
 * but does not format using the American en-US format (comma separated values).
 * It uses a decimal with toFixed method instead
 * @param value to be converted
 * @param numDecimals amount of decimals
 * @returns string representation of the value
 */
export const formatCSVNumber = (
    value: number | null,
    numDecimals: number = 0,
    units?: string,
): string => {
    if (value === null || isNaN(value)) {
        return "";
    }

    const formattedValue = value.toFixed(numDecimals);

    // If the formatted value is -0 (with N decimals), we want to return 0 (with N decimals)
    const zeroString: string = numDecimals === 0 ? "0" : `0.${"0".repeat(numDecimals)}`;
    const negativeZeroString: string = `-${zeroString}`;
    if (formattedValue === negativeZeroString) {
        return addUnitsToFormattedValue(zeroString);
    }

    // Else, return the formatted value
    return addUnitsToFormattedValue(formattedValue, units);
};
