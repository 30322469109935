import { Paper, Skeleton, Stack } from "@mantine/core";
import { Page } from "@flexidao/ui-lib/components";

export const BasicPageSkeleton = (): JSX.Element => (
    <Page dataId="basic-page-skeleton">
        <Paper>
            <Stack>
                <Skeleton height={32} mt="sm" width="60%" radius="sm" />
                <Skeleton height={16} mt="sm" radius="sm" />
                <Skeleton height={16} mt="sm" radius="sm" />
                <Skeleton height={16} mt="sm" width="40%" radius="sm" />
                <Skeleton height={240} mt="sm" radius="sm" />
                <Skeleton height={16} mt="sm" width="100%" radius="sm" />
                <Skeleton height={16} mt="sm" width="90%" radius="sm" />
                <Skeleton height={16} mt="sm" width="100%" radius="sm" />
                <Skeleton height={16} mt="sm" width="80%" radius="sm" />
                <Skeleton height={16} mt="sm" width="100%" radius="sm" />
                <Skeleton height={16} mt="sm" width="90%" radius="sm" />
                <Skeleton height={16} mt="sm" width="100%" radius="sm" />
                <Skeleton height={16} mt="sm" width="40%" radius="sm" />
            </Stack>
        </Paper>
    </Page>
);
