import { ReactElement } from "react";
import { Group, Text } from "@mantine/core";

type FiltersProps = {
    dataId?: string;
    children: ReactElement | Array<ReactElement>;
};

export const Filters = ({ children, dataId }: FiltersProps): ReactElement => (
    <Group spacing="8px" data-id={dataId}>
        <Text weight={600} size={14} sx={{ lineHeight: "16px" }}>
            Filter by:
        </Text>

        {children}
    </Group>
);
