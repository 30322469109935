/* eslint no-magic-numbers: 0 */
import { Space } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionSpaceProps = {
    height: 8 | 16;
    dataId?: string;
};

export const KpiSectionSpace = ({ height, dataId }: KpiSectionSpaceProps): ReactElement => {
    return <Space h={height} data-id={dataId} />;
};
