import { formatMonth, IconCalendar, labelToDataId } from "@flexidao/ui-lib";
import { CSSObject, Flex, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";

type MonthOrPlaceholderProps = {
    month: Date | null;
    dataId: string;
};
const MonthOrPlaceholder = ({ month, dataId }: MonthOrPlaceholderProps): ReactElement => {
    return (
        <Flex w="85px">
            {month && (
                <Text weight={600} size="14px" data-id={dataId}>
                    {formatMonth(month)}
                </Text>
            )}
        </Flex>
    );
};

type MonthRangeInputTextProps = {
    selectedMonthRange: [Date | null, Date | null];
    dataId: string;
};
const MonthRangeInputText = ({
    selectedMonthRange,
    dataId,
}: MonthRangeInputTextProps): ReactElement => {
    const [startTime, endTime] = selectedMonthRange;

    return (
        <Group spacing="2px" align="center">
            <Group spacing="2px" align="center">
                <Text c="flexidaoGrey.3" size="14px">
                    From
                </Text>

                <MonthOrPlaceholder
                    month={startTime}
                    dataId={labelToDataId({
                        prefix: dataId,
                        label: "start-month",
                    })}
                />
            </Group>

            <Group spacing="4px" align="center">
                <Text c="flexidaoGrey.3" size="14px">
                    to
                </Text>

                <MonthOrPlaceholder
                    month={endTime}
                    dataId={labelToDataId({
                        prefix: dataId,
                        label: "end-month",
                    })}
                />
            </Group>
        </Group>
    );
};

type MonthRangeHeaderProps = {
    dataId: string;
    selectedMonthRange: [Date | null, Date | null];
};

export const MonthRangeHeader = ({
    selectedMonthRange,
    dataId,
}: MonthRangeHeaderProps): ReactElement => {
    const mantineTheme = useMantineTheme();

    return (
        <Stack spacing={0} m="md">
            <Group
                data-id={labelToDataId({
                    prefix: dataId,
                    label: "value",
                })}
                sx={(theme): CSSObject => ({
                    paddingBlock: "6px",
                    paddingInline: "8px",
                    borderColor: theme.colors.flexidaoGrey![3],
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: theme.radius.sm,
                    justifyContent: "space-between",
                })}
                w="100%"
                align="center"
            >
                <MonthRangeInputText dataId={dataId} selectedMonthRange={selectedMonthRange} />

                <IconCalendar color={mantineTheme.colors.flexidaoGrey![3]} stroke={1.25} />
            </Group>
        </Stack>
    );
};
