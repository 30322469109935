import { CfeConnectPage, Module } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.CfeConnect}`;

type CfeConnectPaths = {
    [CfeConnectPage.MeterData]: string;
    [CfeConnectPage.Monitoring]: string;
    [CfeConnectPage.MonitoringSiteDetails]: (args: { siteId: string }) => string;
    [CfeConnectPage.EacsAndAttestations]: string;
    [CfeConnectPage.LEGACY_EacData]: string;
};

export const cfeConnectPaths: CfeConnectPaths = {
    // Meter Data
    [CfeConnectPage.MeterData]: `${basePath}/${CfeConnectPage.MeterData}`,
    [CfeConnectPage.Monitoring]: `${basePath}/${CfeConnectPage.Monitoring}`,
    [CfeConnectPage.MonitoringSiteDetails]: ({ siteId }) =>
        `${basePath}/${CfeConnectPage.Monitoring}/site-details/${siteId}`,
    [CfeConnectPage.EacsAndAttestations]: `${basePath}/${CfeConnectPage.EacsAndAttestations}`,
    [CfeConnectPage.LEGACY_EacData]: `${basePath}/${CfeConnectPage.LEGACY_EacData}`,
};
