import { ReactElement } from "react";
import { LegendItem } from "./legend-item";
import { Skeleton, Stack } from "@mantine/core";
import { DonutChartItem, labelToDataId } from "@flexidao/ui-lib";
import { ComponentState } from "@flexidao/dto";
import { LEGEND_ITEM_SKELETON_HEIGHT, LEGEND_ITEM_SKELETON_WIDTH } from "../consts";

type LegendProps = {
    dataIdPrefix: string;
    data: Array<DonutChartItem>;
    isActive: (index: number) => boolean;
    onMouseEnter: (index: number) => void;
    onMouseLeave: () => void;
    state: ComponentState;
};

export const Legend = ({
    dataIdPrefix,
    data,
    isActive,
    onMouseEnter,
    onMouseLeave,
    state,
}: LegendProps): ReactElement => {
    const dataId: string = labelToDataId({
        prefix: dataIdPrefix,
        label: "legend",
    });

    if (state === ComponentState.Loading) {
        return (
            <Stack spacing="4px" justify="start" data-id={dataId}>
                <Skeleton h={LEGEND_ITEM_SKELETON_HEIGHT} w={LEGEND_ITEM_SKELETON_WIDTH} />

                <Skeleton h={LEGEND_ITEM_SKELETON_HEIGHT} w={LEGEND_ITEM_SKELETON_WIDTH} />
            </Stack>
        );
    }

    return (
        <Stack spacing="4px" justify="start" data-id={dataId}>
            {data.map((item, index) => (
                <LegendItem
                    dataIdPrefix={dataId}
                    key={item.label}
                    label={item.label}
                    percentage={item.percentage}
                    value={item.value}
                    numDecimals={item.numDecimals}
                    unit={item.unit}
                    color={item.color}
                    isActive={isActive(index)}
                    onMouseEnter={(): void => onMouseEnter(index)}
                    onMouseLeave={onMouseLeave}
                />
            ))}
        </Stack>
    );
};
