import { v4 as uuidV4 } from "uuid";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { readFileSync } from "fs";

function join(...args: string[]): string {
    let parts: string[] = [];
    for (const arg of args) {
        parts = parts.concat(arg.split("/"));
    }
    const newParts: string[] = [];
    for (let i = 0, l = parts.length; i < l; i++) {
        const part = parts[i];
        if (!part || part === ".") {
            continue;
        }
        if (part === "..") {
            newParts.pop();
        } else {
            newParts.push(part);
        }
    }
    if (parts[0] === "") {
        newParts.unshift("");
    }
    return newParts.join("/") || (newParts.length ? "/" : ".");
}

export const mockAxiosClient = {
    get: (url: string): unknown => {
        const file = readFileSync(join(__dirname, `./${url}/get.json`));
        const mockedData = JSON.parse(file.toString());
        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },

    /**
     *
     * @param url
     * @param body
     * Dynamically set mocked data from authorization middleware based
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    post: (url: string, body: any): unknown => {
        let mockedData;

        //Logic for postSitesByTenantId
        if (url.includes("sites")) {
            const tenantId = url.substring("/api/bim/".length, url.length - "/sites".length);

            mockedData = {
                data: [
                    {
                        siteId: uuidV4(),
                        name: body.name,
                        tenantId: tenantId,
                        siteType: body.siteType,
                        energySource: body.energySourceId,
                        country: {
                            countryId: body.countryId,
                            name: "country",
                            enabled: true,
                        },
                        biddingZone: {
                            zoneId: body.biddingZoneId,
                            name: "biddingZone",
                            enabled: true,
                            country: {
                                countryId: body.countryId,
                                name: "country",
                                enabled: true,
                            },
                            timezones: [body.timezone],
                            dataAccess: "Accessible",
                            region: {
                                regionId: "string",
                                name: "string",
                            },
                        },
                        timezone: body.timezone,
                        consumptionSiteGroupId: null,
                        installedCapacityW: 0,
                        onSite: false,
                    },
                ],
                status: 200,
                statusText: "mock",
                headers: { "content-type": "application/json" },
            };
        } else {
            const clientCredentialScope: Array<{ kind: string; scopeStrings: Array<string> }> =
                body.schemes.filter(
                    (scheme: { kind: string; scopeStrings: Array<string> }) =>
                        scheme.kind === "client-credentials",
                );

            const isAdmin = clientCredentialScope.some((scope) =>
                scope.scopeStrings.some((scopeString) => scopeString.split(":")[1] === "admin"),
            );

            if (isAdmin) {
                const file = readFileSync(join(__dirname, `./${url}/postAdminRole.json`)); // admin permissions
                mockedData = JSON.parse(file.toString()); // here we are mocking the permissions from bim
            } else {
                const file = readFileSync(join(__dirname, `./${url}/postTenantAdminRole.json`)); // tenant permissions
                mockedData = JSON.parse(file.toString()); // here we are mocking the permissions from bim
            }
        }
        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },
};
