import { ReactElement } from "react";
import { Box, Group, Stack, Text } from "@mantine/core";
import {
    flexidaoGrey,
    formatNumber,
    labelToDataId,
    PERCENTAGE_NUM_DECIMALS,
} from "@flexidao/ui-lib";
import {
    OPACITY_ACTIVE,
    OPACITY_INACTIVE,
    SCALE_ACTIVE,
    SCALE_INACTIVE,
} from "@flexidao/ui-lib/components/data-display/charts/donut-chart/consts";

type LegendItemProps = {
    dataIdPrefix: string;
    label: string;
    value: number;
    percentage: number;
    numDecimals: number;
    unit?: string;
    color: string;
    isActive: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

export const LegendItem = ({
    dataIdPrefix,
    label,
    value,
    percentage,
    numDecimals,
    unit,
    color,
    isActive,
    onMouseEnter,
    onMouseLeave,
}: LegendItemProps): ReactElement => {
    const dataId: string = labelToDataId({
        prefix: dataIdPrefix,
        label: `legend-item-${label}`,
    });

    const valueString: string = formatNumber(value, { numDecimals });
    const valueSplitArray: Array<string> = valueString.split(".");
    const valueIntegerPart: string = valueSplitArray[0]!;
    const valueDecimalPart: string | null = valueSplitArray[1] ?? null;

    const percentageString: string = formatNumber(percentage, {
        numDecimals: PERCENTAGE_NUM_DECIMALS,
    });
    const percentageSplitArray: Array<string> = percentageString.split(".");
    const percentageIntegerPart: string = percentageSplitArray[0]!;
    const percentageDecimalPart: string | null = percentageSplitArray[1] ?? null;

    return (
        <Group
            data-id={dataId}
            spacing="4px"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            opacity={isActive ? OPACITY_ACTIVE : OPACITY_INACTIVE}
            sx={{
                transform: isActive ? SCALE_ACTIVE : SCALE_INACTIVE,
                transition: "all 0.3s ease",
                transformOrigin: "left",
            }}
        >
            <Box
                w="16px"
                h="16px"
                bg={color}
                sx={{
                    borderRadius: 4,
                }}
            />

            <Stack spacing={0}>
                <Text
                    size="12px"
                    h="14px"
                    weight={500}
                    c={flexidaoGrey[6]}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "label",
                    })}
                >
                    <Text span>{label}</Text>{" "}
                    <Text span size="12px">
                        ({percentageIntegerPart}
                        <Text span size="10px">
                            .{percentageDecimalPart}%
                        </Text>
                        )
                    </Text>
                </Text>

                <Text
                    size="14px"
                    h="16px"
                    weight={600}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "value",
                    })}
                >
                    <Text span>
                        <Text span size="14px">
                            {valueIntegerPart}
                        </Text>

                        {valueDecimalPart !== null && (
                            <Text span size="10px">
                                .{valueDecimalPart}
                            </Text>
                        )}
                    </Text>

                    {value !== null && unit !== null && (
                        <>
                            {" "}
                            <Text span weight="bold" size="10px">
                                {unit}
                            </Text>
                        </>
                    )}
                </Text>
            </Stack>
        </Group>
    );
};
