import {
    EacCodeUseTypeEnum,
    reportHubComponents,
    TrackingInstrumentType,
    ZoneDataAccessLevel,
} from "./";
import { EnergySource } from "./enum";
import { paths } from "./schemas/report-hub-api-schema";

export type IKind<T> = {
    kind: T;
};

export enum ReportHubFeatureFlag {}

export namespace ReportHubDto {
    export type ContractOption = reportHubComponents["schemas"]["ContractOption"];
    export type UnbundledContractOption = reportHubComponents["schemas"]["UnbundledContractOption"];

    export type GetContractsResponse = Array<ContractOption>;

    export type ContractTrackingKpisPpa = reportHubComponents["schemas"]["ContractTrackingKpisPpa"];
    export type ContractTrackingDirectPpaKpis =
        reportHubComponents["schemas"]["ContractTrackingDirectPpaKpis"];

    export type ContractTrackingOverviewPpaRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewPpaRow"];
    export type DirectPpaContractTrackingOverviewRow =
        reportHubComponents["schemas"]["DirectPpaContractTrackingOverviewRow"];

    export type ContractTrackingKpisGreenTariff =
        reportHubComponents["schemas"]["ContractTrackingKpisGreenTariff"];
    export type ContractTrackingOverviewGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewGreenTariffRow"];

    export type ContractTrackingKpisUnbundled =
        reportHubComponents["schemas"]["ContractTrackingKpisUnbundled"];

    export type ContractTrackingOverviewUnbundledRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewUnbundledRow"];

    export type ContractTrackingMonthlyPpaRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyPpaRow"];

    export type ContractTrackingMonthlyDirectPpaRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyDirectPpaRow"];

    export type ContractTrackingMonthlyGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyGreenTariffRow"];

    export type TrackingInstrumentAssignment =
        reportHubComponents["schemas"]["TrackingInstrumentAssignment"];

    export type TrackingInstrumentAssignmentResponse =
        reportHubComponents["schemas"]["TrackingInstrumentAssignmentResponse"];

    export type Region = {
        regionId: string;
        name: string;
    };
    export type GetRegions = Array<Region>;

    export type ContractTrackingContractOptions = Array<ContractOption>;

    export type ContractTrackingMonthlyPpa = Array<ContractTrackingMonthlyPpaRow>;
    export type ContractTrackingMonthlyGreenTariff = Array<ContractTrackingMonthlyGreenTariffRow>;

    export type AllocationWithInstrumentDetails =
        reportHubComponents["schemas"]["AllocationWithInstrumentDetails"];

    export enum GetAllocationsOrderBy {
        UploadDate = "uploadDate",
        ConsumptionOrganizationName = "consumptionOrganizationName",
        TransactionFromName = "transactionFromName",
        AllocatedTo = "allocatedTo",
        ConsumptionPeriodStart = "consumptionPeriodStart",
        ConsumptionPeriodEnd = "consumptionPeriodEnd",
        ProductionPeriodStart = "productionPeriodStart",
        ProductionPeriodEnd = "productionPeriodEnd",
        VolumeWh = "volumeWh",
    }

    export enum RE100Contribution {
        FullContribution = "full-contribution",
        PartialContribution = "partial-contribution",
        NoContribution = "no-contribution",
        NoContractInfo = "no-contract-info",
    }

    export enum SmartAssignmentStrategy {
        NonSingleMarketOnly = "non-single-market-only",
    }
    export type AllocationAttestation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.Attestation;
        scheme: string | null;
        productionSiteName?: string;
    };

    export type AllocationCancellation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.EAC;
        productionSiteId: string;
        productionSiteName: string;
        consumptionSiteId: string | null;
        productionPeriodStart: Date;
        productionPeriodEnd: Date;
    };

    export type GetAllocationsByTenantIdResponse = {
        allocations: Array<AllocationWithInstrumentDetails>;
        totalAllocations: number;
    };

    export type AllocationKpis = reportHubComponents["schemas"]["AllocationKpis"];

    export type SourcingMethod = reportHubComponents["schemas"]["SourcingMethod"];

    export type LocalContract = reportHubComponents["schemas"]["LocalContract"];

    export type UpdateLocalContractPayload =
        reportHubComponents["schemas"]["UpdateLocalContractPayload"];

    export type EacCode = {
        tenantId: string;
        eacCodeId: number;
        eacCode: string;
        registryId: string;
        useType: EacCodeUseTypeEnum;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
        passiveProcurement: boolean;
    };
    export type CountryName = reportHubComponents["schemas"]["CountryName"];

    export type RegionWithCountry = reportHubComponents["schemas"]["RegionWithCountry"];

    export type BiddingZone = {
        zoneId: string;
        countryId: string;
        name: string;
        timezones: Array<string>;
        enabled: boolean;
        dataAccess: ZoneDataAccessLevel;
        regionId: string;
    };

    export type Market = {
        marketId: string;
        name: string;
        description: string;
    };

    export type AnnualRegionEmissionFactor =
        reportHubComponents["schemas"]["AnnualRegionEmissionFactor"];

    export type AssignmentDetail = reportHubComponents["schemas"]["AssignmentDetail"];

    export type AssignmentDetailAggregated =
        reportHubComponents["schemas"]["AssignmentDetailAggregated"];

    export enum CountryAssignmentsSortField {
        SourcingMethod = "sourcingMethod",
        AssignedWh = "assignedWh",
        ContributionToRe100Wh = "contributionToRe100Wh",
        EnergySourceId = "energySourceId",
        EacSchemeId = "eacSchemeId",
        ProductionCountryName = "productionCountryName",
        ProductionPeriodStart = "productionPeriodStart",
        ProductionSiteCommissioningDateLocal = "productionSiteCommissioningDateLocal",
        ContractStartTimeLocal = "contractStartTimeLocal",
    }

    export type CountryAssignmentsResponse =
        paths["/{tenantId}/assignment/country-assignment"]["get"]["responses"]["200"]["content"]["application/json"];

    export type ContractTesting =
        paths["/admin/contracts/{contractId}"]["get"]["responses"]["200"]["content"]["application/json"];

    export type CountryAssignmentKpis = reportHubComponents["schemas"]["CountryAssignmentKpis"];

    export type GetReportingKpisResponse =
        paths["/{tenantId}/assignment/kpis"]["get"]["responses"]["200"]["content"]["application/json"];

    export type Attestation = {
        attestationId?: string;
        attestationDocumentId?: number;
        consumptionOrganizationName?: string | null;
        consumptionPeriodEnd?: string;
        consumptionPeriodStart?: string;
        consumptionTimezone?: string;
        eacSchemeId?: string | null;
        percentageCoverage?: number;
        productionSiteCommissioningDateLocal?: string | null;
        productionSiteName?: string | null;
        tenantId?: string;
        transactionFrom?: string;
        transactionId?: string;
        quantityWh?: number;
    };

    export type Registry = reportHubComponents["schemas"]["Registry"];

    export type AllocationFilters = {
        transactionIds: Array<string>;
        productionSiteNames: Array<string | null>;
        allocatedToNames: Array<string | null>;
        productionCountries: Array<string | null>;
        energySources: Array<EnergySource | null>;
        registries: Array<Registry | null>;
    };

    export type GlobalOverviewCountryCoverageDetail = {
        tenantId: string;
        countryId: string;
        countryName: string;
        consumptionWh: number;
        purchasedVolumeWh: number;
        trackingInstrumentWh: number;
        coveredShare: number;
        uncoveredWh: number;
    };
    export type GlobalOverviewCountriesCoverage = {
        countriesCoverage: Array<GlobalOverviewCountryCoverageDetail>;
    };

    export type GlobalOverviewKpis = reportHubComponents["schemas"]["GlobalOverviewKpis"];

    export type GlobalOverviewSiteDetail =
        reportHubComponents["schemas"]["GlobalOverviewPerformanceBySiteDetail"];
    export type GlobalOverviewCountryDetail =
        reportHubComponents["schemas"]["GlobalOverviewPerformanceByCountryDetail"];
    export type GlobalOverviewCountriesPerformance =
        paths["/{tenantId}/global-overview/countries-performance"]["get"]["responses"]["200"]["content"]["application/json"];

    export type GlobalOverviewContractDetail =
        reportHubComponents["schemas"]["GlobalOverviewPerformanceByContractDetail"];
    export type GlobalOverviewContractsPerformance =
        paths["/{tenantId}/global-overview/contracts-performance"]["get"]["responses"]["200"]["content"]["application/json"];

    export type GlobalOverviewGroupDetail =
        reportHubComponents["schemas"]["GlobalOverviewPerformanceByGroupDetail"];
    export type GlobalOverviewGroupsPerformance =
        paths["/{tenantId}/global-overview/groups-performance"]["get"]["responses"]["200"]["content"]["application/json"];

    export type GetCountries = Array<Country>;

    export type CountryOption = reportHubComponents["schemas"]["CountryOption"];
    export type ConsumptionSiteGroupOption =
        reportHubComponents["schemas"]["ConsumptionSiteGroupOption"];

    export type GlobalOverviewFiltersCountriesOptions = Array<CountryOption>;
    export type GlobalOverviewFiltersGroupsOptions = Array<ConsumptionSiteGroupOption>;

    export type ReportingPeriod = reportHubComponents["schemas"]["ReportingPeriod"];
    export type GetReportingPeriods = Array<ReportingPeriod>;

    export type GlobalOverviewMonthSiteAggStringifiedBigInts =
        reportHubComponents["schemas"]["GlobalOverviewMonthSiteAgg"];

    export type GlobalOverviewTransactionStringifiedBigInts =
        reportHubComponents["schemas"]["GlobalOverviewTransaction"];

    export type GlobalOverviewSiteAgg = Omit<
        GlobalOverviewMonthSiteAggStringifiedBigInts,
        | "consumptionWh"
        | "coverageWh"
        | "notCoveredWh"
        | "excessWh"
        | "billingGreenTariffWh"
        | "billingPpaWh"
        | "billingVppaWh"
        | "billingUnbundledWh"
    > & {
        consumptionWh: bigint;
        coverageWh: bigint;
        notCoveredWh: bigint;
        excessWh: bigint;
        billingGreenTariffWh: bigint;
        billingPpaWh: bigint;
        billingVppaWh: bigint;
        billingUnbundledWh: bigint;
    };

    export enum ReportingInsightsKind {
        UnassignedCertificatesGap = "UnassignedCertificatesGap",
        TechnicalCriteriaGapNoContributionTransactionsAssigned = "TechnicalCriteriaGapNoContributionTransactionsAssigned",
        TechnicalCriteriaGapTooManyPartialContributionTransactionsAssigned = "TechnicalCriteriaGapTooManyPartialContributionTransactionsAssigned",
        CoverageGap = "CoverageGap",
        NoContractInfoGap = "NoContractInfoGap",

        UnassignedCertificatesGapCountry = "UnassignedCertificatesGapCountry",
        TechnicalCriteriaGapNoContributionTransactionsAssignedCountry = "TechnicalCriteriaGapNoContributionTransactionsAssignedCountry",
        TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedCountry = "TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedCountry",

        TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedRegion = "TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedRegion",

        TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedAssignment = "TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedAssignment",
    }

    export type ReportingInsights = Array<
        | {
              kind:
                  | ReportingInsightsKind.UnassignedCertificatesGap
                  | ReportingInsightsKind.TechnicalCriteriaGapNoContributionTransactionsAssigned
                  | ReportingInsightsKind.TechnicalCriteriaGapTooManyPartialContributionTransactionsAssigned
                  | ReportingInsightsKind.CoverageGap
                  | ReportingInsightsKind.NoContractInfoGap
                  | ReportingInsightsKind.UnassignedCertificatesGapCountry
                  | ReportingInsightsKind.TechnicalCriteriaGapNoContributionTransactionsAssignedCountry
                  | ReportingInsightsKind.TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedCountry;
          }
        | {
              kind:
                  | ReportingInsightsKind.TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedAssignment
                  | ReportingInsightsKind.TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedRegion;
              contributionPartialContributionWh: number;
              maxPartialContributionWh: number;
          }
    >;

    export type RegionAssignmentResponse =
        reportHubComponents["schemas"]["RegionAssignmentResponse"];

    export type RegionAssignmentsResponse =
        reportHubComponents["schemas"]["RegionAssignmentsResponse"];

    export type ReportingPeriodResponse = reportHubComponents["schemas"]["ReportingPeriodResponse"];

    export type TrackingInstrumentStateResponse =
        reportHubComponents["schemas"]["TrackingInstrumentStateResponse"];

    export type SmartAssignmentResponse = reportHubComponents["schemas"]["SmartAssignmentResponse"];
    export type SmartAssignment = reportHubComponents["schemas"]["SmartAssignment"];
    export type SingleSmartAssignment = reportHubComponents["schemas"]["SingleSmartAssignment"];

    export type GlobalOverviewMonthSiteAgg =
        reportHubComponents["schemas"]["GlobalOverviewMonthSiteAgg"];
}
