import { EacCodeUseTypeEnum, EacProvidersEnum, EnergySource, SiteType } from "./enum";

import { components, paths } from "./schemas/eac-data-api-schema";
import { InferApiFunctionReturn } from "./types";

export type IKind<T> = {
    kind: T;
};
export namespace EacDataDto {
    export type Tenant = {
        tenantId: string;
        name?: string;
    };

    export type TenantProvider = {
        tenantId: string;
        providerId: EacProvidersEnum;
        enabled: boolean;
    };

    export type Credential = {
        credentialId: string;
        name: string;
        description: string;
        secret: {
            iv: string;
            content: string;
        };
        expirationDate?: Date;
    };

    export type BaseEacCode = {
        eacCodeId: number;
        tenantId: string;
        providerId?: EacProvidersEnum;
        eacCode: string;
        registryId: string;
        initialTimeToCheck: Date;
        enabled: boolean;
        useType: EacCodeUseTypeEnum;
        credentialsId?: string;
        siteId?: string;
    };

    export type UpdateEacCodePayload = {
        initialTimeToCheck?: Date;
        enabled?: boolean;
        useType?: EacCodeUseTypeEnum;
        siteId?: string;
    };

    export type EacCodeWithRegistry = Omit<BaseEacCode, "registryId"> & {
        registry: BaseRegistry;
    };

    export type BaseRegistry = {
        registryId: string;
        countryIds: Array<string>;
        name: string;
        eacSchemeId: string;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
    };

    export type BaseSite = {
        siteId: string;
        tenantId: string;
        name: string;
        siteType: SiteType;
        energySourceId?: EnergySource;
        countryId: string;
        biddingZoneId: string;
        timezone: string;
        eacCodeIds?: Array<number>;
        syncVersion: Date;
    };

    export type EacSchemeOption = {
        eacSchemeId: string;
        name: string;
    };

    export type UpdateSiteEacCodePayload = { siteId: string; eacCodeIds: Array<number> };

    export type CredentialsPayload = {
        credentialId: string;
    };

    export type UpdateContractEacCodesPayload = {
        contractId: string;
        cancellationParty: number | null;
        beneficiaries: ReadonlyArray<number>;
    };

    export type AttestationWithMeta = BaseAttestation & {
        created: Date;
        updated: Date;
        deleted: Date | null;
    };

    export type EACProofOfCancellationProduction = {
        siteId: string;
        siteCommissioningDate: Date | null;
        periodStart: Date;
        periodEnd: Date;
        energySource: string;
        siteName: string;
        timezone: string;
    };

    export type EACProofOfCancellationConsumption = {
        siteId: string | null;
        periodStart: Date;
        periodEnd: Date;
        organizationName: string | null;
        organizationId: string | null;
        timezone: string;
    };

    export type EACProofOfCancellationGuarantees = {
        from: string | null;
        to: string | null;
        issuance: string | null;
    };

    export type EACProofOfCancellationTransaction = {
        id: string;
        timestamp: Date | null;
        timezone: string | null;
        fromId: string | null;
        fromName: string | null;
        toId: string | null;
        toName: string | null;
        productionCountry: string | null;
        quantity: number;
        documentId: number | null;
        registryId: string;
        energySourceId: string;
    };

    export type EACProofOfCancellation = {
        production: EACProofOfCancellationProduction;
        consumption: EACProofOfCancellationConsumption;
        guarantees: EACProofOfCancellationGuarantees;
        transaction: EACProofOfCancellationTransaction;
        tenantId: string;
        executionId: string | null;
        // TODO: This should be changed to eacProofOfCancellationId when we change it for event digestion
        eacCancellationId: string;
    };

    export type EACProofOfCancellationFull = EACProofOfCancellation & {
        updated: Date;
        deleted: Date | null;
    };

    export type BaseProofOfCancellation = {
        proofOfCancellationId: string;
        productionCountry: string | null;
        tenantId: string;
        documentId: number | null;
        executionId: string;
        consumptionSiteId: string | null;
        quantityWh: number;
        guaranteesIdFrom: string | null;
        guaranteesIdTo: string | null;
        guaranteesIssuanceId: string | null;
        registryTransactionId: string;
        registryTransactionTimestamp: Date | null;
        registryTransactionTimezone: string | null;
        registryId: string;
        energySourceId: EnergySource;
        transactionFromName: string | null;
        transactionFromId: string | null;
        transactionToName: string | null;
        transactionToId: string | null;
        productionPeriodStart: Date;
        productionPeriodEnd: Date;
        productionPeriodTimezone: string;
        productionSiteId: string;
        productionSiteName: string;
        productionSiteCommissioningDateLocal: Date | null;
        consumptionOrganizationName: string | null;
        consumptionOrganizationId: string | null;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        consumptionPeriodTimezone: string;
        productionState: string | null;
    };

    export type ProofOfCancellationDetail = components["schemas"]["ProofOfCancellationDetail"];

    export type BaseAttestation = {
        attestationId: string;
        transactionId: string;
        tenantId: string;
        attestationDocumentId: number;
        eacSchemeId: string | null;
        transactionFrom: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        consumptionTimezone: string;
        quantityWh: number;
        consumptionOrganizationName: string | null;
        energySourceId: EnergySource | null;
        productionSiteCommissioningDateLocal: Date | null;
        productionCountryId: string | null;
        productionSiteName: string | null;
        executionId: string;
    };

    export type AttestationDetail = BaseAttestation & {
        energySourceName: string | null;
        productionCountryName: string | null;
    };

    export type GetAttestationsSortField =
        | "transactionFrom"
        | "productionSiteCommissioningDateLocal";

    export type ProofOfCancellationTotals = InferApiFunctionReturn<
        paths,
        "/{tenantId}/eac-proof-of-cancellation-totals"
    >;

    export enum GetProofOfCancellationsSortField {
        TransactionFromName = "transactionFromName",
        ProductionSiteCommissioningDateLocal = "productionSiteCommissioningDateLocal",
        QuantityWh = "quantityWh",
    }
    export type GetProofOfCancellationsResponse = InferApiFunctionReturn<
        paths,
        "/{tenantId}/eac-proof-of-cancellations"
    >;

    export type GetAttestationsResponse = {
        totalCount: number;
        attestations: Array<AttestationDetail>;
    };

    export type EnergySourceOption = {
        energySourceId: EnergySource;
        name: string;
    };
    export type CountryOption = {
        countryId: string;
        name: string;
    };

    export type GetAttestationsFilterOptionsResponse = {
        countries: Array<CountryOption>;
        eacSchemes: Array<EacSchemeOption>;
        energySources: Array<EnergySourceOption>;
    };

    export type ProcessedCertificateFromPdf = {
        registry?: string | null;
        productionCountry?: string | null;
        productionState?: string | null;
        transactionType?: string | null;
        transactionStatus?: string | null;
        transactionId?: string | null;
        transactionDate?: string | null;
        transactionTimezone?: string | null;
        fromCertificateId?: string | null;
        toCertificateId?: string | null;
        issuanceId?: string | null;
        quantity?: string | number | null;
        transactionFromName?: string | null;
        transactionFromId?: string | null;
        transactionToName?: string | null;
        transactionToId?: string | null;
        productionPeriodStart?: string | null;
        productionPeriodEnd?: string | null;
        productionTimezone?: string | null;
        productionSiteName?: string | null;
        productionSiteId?: string | null;
        productionSiteCommissioningDate?: string | null;
        energySource?: string | null;
        consumptionOrganizationName?: string | null;
        consumptionOrganizationId?: string | null;
        consumptionSiteId?: string | null;
        consumptionPeriodStart?: string | null;
        consumptionPeriodEnd?: string | null;
        consumptionTimezone?: string | null;
        attachedSourceDocumentName?: string | null;
    };

    export type UploadEacPdfResponse = {
        certificates: Array<ProcessedCertificateFromPdf>;
    };

    export type EacCsvError = components["schemas"]["EacCsvError"];

    export type SupportedEacFormat = {
        id: string;
        displayName: string;
        modelVersion?: string | null;
        outputVersion?: string | null;
    };
}

// TODO change it
export namespace EacProviderDto {
    export type RateLimitEnum =
        | "RateLimitOnSeconds"
        | "RateLimitOnMinutes"
        | "RateLimitOnHours"
        | "RateLimitOnDays"
        | "NoRateLimit";

    export type RateLimitOnSeconds = {
        seconds: number;
        limit: number;
    } & IKind<"RateLimitOnSeconds">;

    export type RateLimitOnMinutes = {
        minutes: number;
        limit: number;
    } & IKind<"RateLimitOnMinutes">;
    export type RateLimitOnHours = {
        hours: number;
        limit: number;
    } & IKind<"RateLimitOnHours">;
    export type RateLimitOnDays = {
        days: number;
        limit: number;
    } & IKind<"RateLimitOnDays">;
    export type NoRateLimit = IKind<"NoRateLimit">;

    export type RateLimit =
        | NoRateLimit
        | RateLimitOnSeconds
        | RateLimitOnMinutes
        | RateLimitOnHours
        | RateLimitOnDays;

    type CredentialsLevel = "Flexidao" | "TenantProvider" | "EacCode";

    export type Provider = {
        providerId: EacProvidersEnum;
        recheckAvailable: boolean;
        credentialLevel: CredentialsLevel;
        rateLimits: Array<RateLimit>;
    };
}
