import { CfeInventoryPage, Module } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.CfeInventory}`;

type CfeInventoryPaths = {
    [CfeInventoryPage.TwentyFourSevenCfeDashboard]: string;
    [CfeInventoryPage.PpaFinancialDashboard]: string;
    [CfeInventoryPage.ContractTracking]: string;
    [CfeInventoryPage.TransactionsAllocation]: string;
    [CfeInventoryPage.ReportingDashboard]: string;
    [CfeInventoryPage.ReportingDashboardCountry]: (args: { countryId: string }) => string;
    [CfeInventoryPage.AssignmentOverview]: (args: {
        year: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.RegionAssignment]: (args: {
        year: string;
        regionId: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.EditRegionAssignment]: (args: {
        year: string;
        regionId: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.GlobalOverview]: string;
    [CfeInventoryPage.LEGACY_GlobalOverviewV2]: string;
};

export const cfeInventoryPaths: CfeInventoryPaths = {
    // 24/7
    [CfeInventoryPage.TwentyFourSevenCfeDashboard]: `${basePath}/${CfeInventoryPage.TwentyFourSevenCfeDashboard}`,
    [CfeInventoryPage.PpaFinancialDashboard]: `${basePath}/${CfeInventoryPage.PpaFinancialDashboard}`,

    // Report Hub
    [CfeInventoryPage.ContractTracking]: `${basePath}/${CfeInventoryPage.ContractTracking}`,
    [CfeInventoryPage.TransactionsAllocation]: `${basePath}/${CfeInventoryPage.TransactionsAllocation}`,
    [CfeInventoryPage.ReportingDashboard]: `${basePath}/${CfeInventoryPage.ReportingDashboard}`,
    [CfeInventoryPage.ReportingDashboardCountry]: ({ countryId }) =>
        `${basePath}/${CfeInventoryPage.ReportingDashboardCountry}/${countryId}`,
    [CfeInventoryPage.AssignmentOverview]: ({ year, consumptionSiteGroupId }) =>
        `${basePath}/${CfeInventoryPage.AssignmentOverview}/${year}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.RegionAssignment]: ({ year, regionId, consumptionSiteGroupId }) =>
        `${basePath}/${CfeInventoryPage.RegionAssignment}/${year}/${regionId}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.EditRegionAssignment]: ({ year, regionId, consumptionSiteGroupId }): string =>
        `${basePath}/${CfeInventoryPage.EditRegionAssignment}/${year}/${regionId}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.GlobalOverview]: `${basePath}/${CfeInventoryPage.GlobalOverview}`,
    [CfeInventoryPage.LEGACY_GlobalOverviewV2]: `${basePath}/${CfeInventoryPage.LEGACY_GlobalOverviewV2}`,
};
