import { Center, Loader } from "@mantine/core";
import { Page } from "@flexidao/ui-lib/components";

export const AppLoading = (): JSX.Element => (
    <Page dataId="app-loading">
        <Center h="calc(100vh - 4rem)">
            <Loader size="lg" variant="dots" />
        </Center>
    </Page>
);
