import { Button, ButtonColors, ButtonVariants, labelToDataId } from "@flexidao/ui-lib";
import {
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import { SelectFilterItem } from "./types";

type SelectFilterProps<T extends string = string> = {
    title: string;
    options: Array<SelectFilterItem<T>>;
    disabled?: boolean;
    appliedValue: T;
    handleApply: (val: T) => void;
    dataIdPrefix: string;
    noDataLabel?: string;
    icon?: ReactElement;
};

export const SelectFilter = <T extends string>({
    title,
    options,
    disabled = false,
    dataIdPrefix,
    appliedValue,
    handleApply,
    noDataLabel = "No data",
    icon = <IconChevronDown size={16} />,
}: SelectFilterProps<T>): ReactElement => {
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<T>(appliedValue);

    useEffect(() => {
        setSelectedValue(appliedValue);
    }, [appliedValue]);

    const confirmSelection = (): void => {
        handleApply(selectedValue);
        setFilterOpen(false);
    };

    const selectedLabel: string =
        options.length === 0
            ? noDataLabel
            : (options.find((item) => item.value === appliedValue)?.label ?? "Please select");

    return (
        <Popover
            opened={filterOpen}
            onChange={(opened: boolean): void => setFilterOpen(opened)}
            position="bottom-start"
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  setFilterOpen(!filterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "target",
                    })}
                >
                    <Group position="apart" align="center">
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text
                                truncate
                                fz="12px"
                                lh="14px"
                                size="sm"
                                c="flexidaoGrey.8"
                                maw="318px"
                            >
                                {selectedLabel}
                            </Text>
                        </Stack>

                        {icon}
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={{
                        minWidth: "250px",
                        maxWidth: "400px",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "dropdown",
                    })}
                >
                    <Stack
                        spacing={0}
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                        p="0.25rem"
                    >
                        {options.map((item) => {
                            const isSelected: boolean = selectedValue === item.value;

                            return (
                                <Text
                                    py="0.5rem"
                                    px="md"
                                    key={item.value}
                                    data-id={labelToDataId({
                                        prefix: `${dataIdPrefix}-select-item`,
                                        label: `${item.value}`,
                                    })}
                                    onClick={(): void => {
                                        setSelectedValue(item.value);
                                    }}
                                    sx={(theme): CSSObject => ({
                                        "&:hover": {
                                            backgroundColor: isSelected
                                                ? "none"
                                                : theme.colors.green[0],
                                            cursor: "pointer",
                                            borderRadius: "0.5rem",
                                        },
                                        background: isSelected ? theme.colors.green[5] : "none",
                                        borderRadius: "0.5rem",
                                        color: isSelected ? theme.white : theme.black,
                                        fontWeight: isSelected ? "bold" : "normal",
                                        fontSize: "0.875rem",
                                        lineHeight: "1.25rem",
                                    })}
                                >
                                    {item.label}
                                </Text>
                            );
                        })}
                    </Stack>

                    <Divider />

                    <Group p="md" position="right">
                        <Button
                            dataId={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "confirm-button",
                            })}
                            variant={ButtonVariants.Filled}
                            color={ButtonColors.Green}
                            disabled={selectedValue === appliedValue}
                            onClick={confirmSelection}
                        >
                            Apply selection
                        </Button>
                    </Group>
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
