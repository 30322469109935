const US_ENGLISH_LOCALE = "en-US";

const addSignToFormattedValue = (formattedValue: string, value: number): string => {
    return value > 0 ? `+${formattedValue}` : formattedValue;
};

const addUnitsToFormattedValue = (formattedValue: string, units?: string): string => {
    return units ? `${formattedValue} ${units}` : formattedValue;
};

type FormatNumberOptions = {
    numDecimals?: number;
    units?: string;
    showSign?: boolean;
    roundZero?: boolean;
};
export const formatNumber = (value: number | null, options?: FormatNumberOptions): string => {
    const {
        numDecimals = 0,
        units,
        showSign = false,
        roundZero = true,
    } = options ??
    ({
        numDecimals: 0,
        units: undefined,
        showSign: false,
        roundZero: true,
    } satisfies FormatNumberOptions);

    if (value == null || isNaN(value)) {
        return "-";
    }

    const formattedValue: string = value.toLocaleString(US_ENGLISH_LOCALE, {
        minimumFractionDigits: numDecimals,
        maximumFractionDigits: numDecimals,
    });

    // If the formatted value is 0 or -0 (with N decimals), we want to return 0 (with N decimals)
    const zeroString: string = numDecimals === 0 ? "0" : `0.${"0".repeat(numDecimals)}`;
    const negativeZeroString: string = `-${zeroString}`;

    if (formattedValue === negativeZeroString) {
        return addUnitsToFormattedValue(zeroString, units);
    }

    if (value > 0 && formattedValue === zeroString) {
        if (roundZero) {
            return addUnitsToFormattedValue(zeroString, units);
        } else {
            const almostZeroString: string =
                numDecimals === 0 ? "<1" : `<0.${"0".repeat(numDecimals - 1)}1`;
            return addUnitsToFormattedValue(almostZeroString, units);
        }
    }

    // Else, return the formatted value
    const formattedValueWithSign: string = showSign
        ? addSignToFormattedValue(formattedValue, value)
        : formattedValue;

    return addUnitsToFormattedValue(formattedValueWithSign, units);
};
