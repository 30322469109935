import {
    ColumnType,
    compareValues,
    getBooleanValue,
    getCustomValue,
    getDatetimeValue,
    getDateValue,
    getDivisionValue,
    getLinkValue,
    getNumberValue,
    getStringValue,
    labelToDataId,
} from "@flexidao/ui-lib";
import { MRT_ColumnDef } from "mantine-react-table";
import { RowData } from "../../types";
import { Column, ColumnFilterProps, FilterType } from "../types";

const getFilterProps = <T extends RowData>(
    filterProps: ColumnFilterProps | null,
): Partial<MRT_ColumnDef<T>> => {
    const mrtFilterProps: Partial<MRT_ColumnDef<T>> = {
        enableColumnFilter: filterProps != null,
    };

    if (filterProps == null) {
        return mrtFilterProps;
    }

    switch (filterProps.filterType) {
        case FilterType.Text:
            mrtFilterProps.filterVariant = "text";
            break;
        case FilterType.Select:
            mrtFilterProps.filterVariant = "select";
            mrtFilterProps.mantineFilterSelectProps = {
                data: filterProps.options,
            };
            break;
        case FilterType.MultiSelect:
            mrtFilterProps.filterVariant = "multi-select";
            mrtFilterProps.mantineFilterMultiSelectProps = {
                data: filterProps.options,
            };
            break;
    }

    return mrtFilterProps;
};
export const getBaseColumn = <T extends RowData>({
    accessorKey,
    header,
    enableSorting = false,
    filterProps,
    minSize,
    maxSize,
    enableClickToCopy = false,
    columnType,
    editProps,
    Footer,
}: Column<T>): MRT_ColumnDef<T> => {
    const baseColumn: MRT_ColumnDef<T> = {
        mantineTableHeadCellProps: {
            id: labelToDataId({
                prefix: header,
                label: "table-header",
            }),
        },
        accessorKey,
        header,
        enableSorting,
        ...getFilterProps(filterProps ?? null),
        size:
            minSize !== undefined && maxSize !== undefined && minSize === maxSize
                ? minSize
                : undefined,
        minSize,
        maxSize,
        enableClickToCopy,
        mantineEditTextInputProps: ({ row }) => ({
            type: editProps?.type ?? "text",
            required: editProps?.required ?? false,
            error: editProps?.error,
            value: row.original[accessorKey],
            onBlur: (event): void => {
                editProps?.onBlur?.({
                    row: row.original,
                    value: event.currentTarget.value,
                });
            },
            onChange: (event): void => {
                editProps?.onChange?.({
                    row: row.original,
                    value: event.currentTarget.value,
                });
            },
        }),
        sortingFn: <
            TData extends {
                original: T;
            },
        >(
            { original: rowA }: TData,
            { original: rowB }: TData,
            columnId: string,
        ): number => {
            switch (columnType) {
                case ColumnType.Number: {
                    return compareValues(
                        getNumberValue(rowA[columnId]),
                        getNumberValue(rowB[columnId]),
                    );
                }
                case ColumnType.String: {
                    return compareValues(
                        getStringValue(rowA[columnId]),
                        getStringValue(rowB[columnId]),
                    );
                }
                case ColumnType.Link: {
                    return compareValues(
                        getLinkValue(rowA[columnId]),
                        getLinkValue(rowB[columnId]),
                    );
                }
                case ColumnType.Boolean: {
                    return compareValues(
                        getBooleanValue(rowA[columnId]),
                        getBooleanValue(rowB[columnId]),
                    );
                }
                case ColumnType.Date: {
                    return compareValues(
                        getDateValue(rowA[columnId]),
                        getDateValue(rowB[columnId]),
                    );
                }
                case ColumnType.Datetime: {
                    return compareValues(
                        getDatetimeValue(rowA[columnId]),
                        getDatetimeValue(rowB[columnId]),
                    );
                }
                case ColumnType.Division: {
                    return compareValues(
                        getDivisionValue(rowA[columnId]),
                        getDivisionValue(rowB[columnId]),
                    );
                }
                case ColumnType.Custom: {
                    return compareValues(
                        getCustomValue(rowA[columnId]),
                        getCustomValue(rowB[columnId]),
                    );
                }
            }
        },
        filterFn: <
            TData extends {
                original: T;
            },
        >(
            row: TData,
            columnId: string,
            filterValue: string | Array<string> | null,
        ): boolean => {
            // If the filter value is null or an empty array, the row should be displayed
            if (filterValue == null || (Array.isArray(filterValue) && filterValue.length === 0)) {
                return true;
            }

            // Else, the row should be displayed if the filter value includes the cell value
            let cellValue: string | null = null;
            switch (columnType) {
                case ColumnType.Number: {
                    cellValue = getNumberValue(row.original[columnId])?.toString() ?? null;
                    break;
                }
                case ColumnType.String: {
                    cellValue = getStringValue(row.original[columnId]);
                    break;
                }
                case ColumnType.Link: {
                    cellValue = getLinkValue(row.original[columnId]);
                    break;
                }
                case ColumnType.Boolean: {
                    cellValue = getBooleanValue(row.original[columnId])?.toString() ?? null;
                    break;
                }
                case ColumnType.Date: {
                    cellValue = getDateValue(row.original[columnId])?.toISOString() ?? null;
                    break;
                }
                case ColumnType.Datetime: {
                    cellValue = getDatetimeValue(row.original[columnId])?.toISOString() ?? null;
                    break;
                }
                case ColumnType.Division: {
                    cellValue = getDivisionValue(row.original[columnId])?.toString() ?? null;
                    break;
                }
                case ColumnType.Custom: {
                    cellValue = getCustomValue(row.original[columnId])?.toString() ?? null;
                    break;
                }
            }

            if (Array.isArray(filterValue)) {
                return filterValue.some((value): boolean => compareValues(value, cellValue) === 0);
            } else {
                return compareValues(filterValue, cellValue) === 0;
            }
        },
        Footer,
    };

    return baseColumn;
};
