import { Button, Group } from "@mantine/core";
import { ReactElement, useMemo } from "react";
import { getShouldEnableButtons } from "../utils";

type MonthRangeActionsProps = {
    selectedMonthRange: [Date | null, Date | null];
    initialMonthRange: [Date, Date];
    handleMonthCleared: () => void;
    handleMonthApplied: () => void;
};

export const MonthRangeActions = ({
    selectedMonthRange,
    initialMonthRange,
    handleMonthCleared,
    handleMonthApplied,
}: MonthRangeActionsProps): ReactElement => {
    // Enable apply button if the selected month range is different from the applied month range
    const { enableApplyButton, enableClearButton } = useMemo(
        () => getShouldEnableButtons(selectedMonthRange, initialMonthRange),
        [selectedMonthRange, initialMonthRange],
    );

    const onApplyButtonClick = (): void => {
        if (!enableApplyButton) {
            return;
        }

        handleMonthApplied();
    };

    const onClearButtonClick = (): void => {
        if (!enableClearButton) {
            return;
        }

        handleMonthCleared();
    };

    return (
        <Group p="md" position="right">
            <Button
                onClick={onClearButtonClick}
                disabled={!enableClearButton}
                data-id="month-range-picker--clear"
                variant="outline"
                className="outline"
                style={{
                    textTransform: "uppercase",
                }}
            >
                Clear
            </Button>

            <Button
                onClick={onApplyButtonClick}
                disabled={!enableApplyButton}
                data-id="month-range-picker--apply"
                style={{
                    textTransform: "uppercase",
                }}
            >
                Apply selection
            </Button>
        </Group>
    );
};
