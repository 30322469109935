import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { labelToDataId } from "@flexidao/ui-lib";

type DownloadModalContentProps = {
    dataDescription: string;
    dataIdPrefix: string;
};

export const DownloadModalContent = ({
    dataDescription,
    dataIdPrefix,
}: DownloadModalContentProps): ReactElement => (
    <Text
        data-id={labelToDataId({
            prefix: dataIdPrefix,
            label: "download-modal-content",
        })}
    >
        You are about to download a CSV file containing the {dataDescription} for the selected
        filters.
        <br />
        <br />
        Are you sure you want to proceed?
    </Text>
);
