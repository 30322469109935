import { Stack, Text, Title } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionTitleProps = {
    title: string;
    description?: string;
    dataId?: string;
};

export const KpiSectionTitle = ({
    title,
    description,
    dataId,
}: KpiSectionTitleProps): ReactElement => (
    <Stack align="flex-start" px="xl" py={0} data-id={dataId} spacing={0}>
        <Title order={3}>{title}</Title>
        <Text fz="14px">{description}</Text>
    </Stack>
);
