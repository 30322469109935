import { ReactElement } from "react";
import { Group } from "@mantine/core";

type KpiGroupProps = {
    dataId: string;
    children: Array<ReactElement>;
};

export const KpiGroup = ({ dataId, children }: KpiGroupProps): ReactElement => (
    <Group data-id={dataId} spacing="8px" align="stretch" grow>
        {children}
    </Group>
);
