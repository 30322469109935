import {
    IconDownload,
    IconWithTooltip,
    labelToDataId,
    Modal,
    NotificationVariant,
    showNotification,
    TooltipIconType,
} from "@flexidao/ui-lib";
import { ActionIcon } from "@mantine/core";
import { ReactElement, useState } from "react";
import { DownloadModalContent } from "./download-modal-content";

export type DownloadButtonProps = {
    dataIdPrefix: string;
    downloadCallback: () => void;
    tooltipContent: string;
    dataTitle: string;
    dataDescription: string;
    disabled?: boolean;
};

export const DownloadButton = ({
    dataIdPrefix,
    downloadCallback,
    tooltipContent,
    dataTitle,
    dataDescription,
    disabled,
}: DownloadButtonProps): ReactElement => {
    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const handleModalAccepted = (): void => {
        // Download the data, close the modal, and show a notification
        downloadCallback();
        setModalOpened(false);
        showNotification({
            variant: NotificationVariant.Success,
            id: labelToDataId({
                prefix: dataIdPrefix,
                label: "download-started-notification",
            }),
            message: "Your download will start soon, please wait",
        });
    };

    const handleModalRejected = (): void => {
        // Close the modal without downloading the data
        setModalOpened(false);
    };

    return (
        <>
            <IconWithTooltip
                tooltip={{
                    iconType: TooltipIconType.Custom,
                    icon: (
                        <ActionIcon
                            sx={{
                                cursor: "pointer",
                            }}
                            color="flexidaoGrey.9"
                            variant="transparent"
                            disabled={disabled}
                        >
                            <IconDownload size={20} />
                        </ActionIcon>
                    ),
                    onClick: (): void => setModalOpened(true),
                    content: tooltipContent,
                }}
                dataId={labelToDataId({
                    prefix: dataIdPrefix,
                    label: "download-button",
                })}
                disabled={disabled}
            />

            <Modal
                dataId={labelToDataId({
                    prefix: dataIdPrefix,
                    label: "download-modal",
                })}
                title={dataTitle}
                isOpen={modalOpened}
                content={
                    <DownloadModalContent
                        dataDescription={dataDescription}
                        dataIdPrefix={dataIdPrefix}
                    />
                }
                rejectProps={{
                    onReject: handleModalRejected,
                    text: "No, go back",
                }}
                acceptProps={{
                    onAccept: handleModalAccepted,
                    text: "Yes, download",
                }}
            />
        </>
    );
};
