import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { IconChevronRight, labelToDataId } from "@flexidao/ui-lib";
import { Anchor, Breadcrumbs as MantineBreadcrumbs } from "@mantine/core";
import { Header } from "./header";

type BreadcrumbsProps = {
    dataId: string;
    currentPageTitle?: string;
    parents: Array<{
        dataId?: string;
        title?: string;
        pathname: string;
        search?: string;
    }>;
};
export const Breadcrumbs = ({
    dataId: dataIdPrefix,
    currentPageTitle,
    parents,
}: BreadcrumbsProps): ReactElement => (
    <MantineBreadcrumbs separator={<IconChevronRight />} data-id={dataIdPrefix}>
        {parents.map(({ dataId, title, pathname, search }) => (
            <Anchor
                component={Link}
                to={{
                    pathname,
                    search,
                }}
            >
                <Header
                    dataId={labelToDataId({
                        prefix: dataIdPrefix,
                        label: dataId ?? title ?? "skeleton",
                    })}
                    title={title}
                />
            </Anchor>
        ))}

        <Header
            dataId={labelToDataId({
                prefix: dataIdPrefix,
                label: currentPageTitle ?? "skeleton",
            })}
            title={currentPageTitle}
        />
    </MantineBreadcrumbs>
);
