import { ReactElement } from "react";
import { IconWithTooltip, labelToDataId, TooltipType } from "@flexidao/ui-lib";
import { Group, Text } from "@mantine/core";

type HeaderWithTooltipProps = {
    title: string;
    tooltip?: TooltipType;
};

export const HeaderWithTooltip = ({ title, tooltip }: HeaderWithTooltipProps): ReactElement => (
    <Group
        noWrap
        align="center"
        spacing={2}
        data-id={labelToDataId({
            prefix: title,
            label: "header",
            suffix: tooltip ? "with-tooltip" : undefined,
        })}
    >
        {tooltip && (
            <IconWithTooltip
                tooltip={tooltip}
                dataId={labelToDataId({
                    prefix: title,
                    label: "tooltip",
                })}
            />
        )}

        <Text size={14} weight="bold">
            {title}
        </Text>
    </Group>
);
