import { ReactElement } from "react";
import { CSSObject, Group, SegmentedControl } from "@mantine/core";
import { TabOption, TabVariant } from "./types";
import { getStylesFromTabVariant } from "./utils";

type TabProps<T extends string> = {
    dataId: string;
    selectedTab: T;
    onSelectedTabChange: (tab: T) => void;
    tabList: Array<TabOption<T>>;
    tabVariant: TabVariant;
    disabled?: boolean;
};

export const Tabs = <T extends string>({
    dataId,
    selectedTab,
    onSelectedTabChange,
    tabList,
    tabVariant,
    disabled = false,
}: TabProps<T>): ReactElement => {
    const { color, backgroundColor } = getStylesFromTabVariant(tabVariant);

    return (
        <Group p="left">
            <SegmentedControl
                id={dataId}
                data-id={dataId}
                transitionDuration={100}
                value={selectedTab}
                onChange={onSelectedTabChange}
                w="fit-content"
                color={color}
                bg={backgroundColor}
                sx={(theme): CSSObject => ({
                    boxShadow: theme.shadows.xs,
                })}
                styles={{
                    control: {
                        border: "0px !important",
                    },
                    label: {
                        paddingBlock: "8px",
                        paddingInline: "16px",
                    },
                }}
                data={tabList}
                disabled={disabled}
            />
        </Group>
    );
};
