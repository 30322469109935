import { ReactElement } from "react";
import { Box, Text } from "@mantine/core";
import { DonutChartCenterElement, formatNumber } from "@flexidao/ui-lib";

type CenterElementProps = {
    centerElement: DonutChartCenterElement;
};
export const CenterElement = ({ centerElement }: CenterElementProps): ReactElement => {
    // If there is a center element, we need to format its value
    const centerElementValueString: string = formatNumber(centerElement?.value ?? null, {
        numDecimals: centerElement?.numDecimals,
    });
    const centerElementValueSplitArray: Array<string> = centerElementValueString.split(".");
    const centerElementIntegerPart: string = centerElementValueSplitArray[0]!;
    const centerElementDecimalPart: string | null = centerElementValueSplitArray[1] ?? null;

    return (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
            }}
        >
            <Text>
                <Text span>
                    <Text span weight="bold" ff="Lexend Deca" size="24px" lh="30px">
                        {centerElementIntegerPart}
                    </Text>

                    {centerElementDecimalPart !== null && (
                        <Text span weight="bold" ff="Lexend Deca" size="18px" lh="23px">
                            .{centerElementDecimalPart}
                        </Text>
                    )}
                </Text>

                {centerElement.unit !== null && (
                    <Text span weight="bold" size="8px">
                        {centerElement.unit}
                    </Text>
                )}
            </Text>
        </Box>
    );
};
