import { ReactNode } from "react";
import { MRT_Row, MRT_TableState, MRT_VisibilityState } from "mantine-react-table";
import { DataControllerType, RowData, TableState, Updater } from "./types";
import { Column } from "./columns/types";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGINATION } from "./pagination/consts";
import { PaginationState } from "./pagination/types";
import { sortingToMrtSorting } from "./sorting/utils";
import { filtersToMrtFilters } from "./filtering/utils";

export const getColumnVisibility = <T extends RowData>(
    columns: Array<Column<T>>,
): MRT_VisibilityState =>
    columns.reduce((acc: MRT_VisibilityState, column: Column<T>) => {
        acc[column.accessorKey] = column.visible ?? true;
        return acc;
    }, {});

export const getShouldDisplayPagination = ({
    dataControllerType,
    rowCount,
    disablePagination,
    pagination,
    rowsPerPageOptions,
}: {
    dataControllerType: DataControllerType;
    rowCount: number;
    disablePagination?: boolean;
    pagination?: PaginationState;
    rowsPerPageOptions?: Array<number>;
}): boolean => {
    if (
        disablePagination ||
        (dataControllerType === DataControllerType.Controlled && pagination == null)
    ) {
        return false;
    }

    if (rowsPerPageOptions == null || rowsPerPageOptions.length === 0) {
        return rowCount > DEFAULT_PAGE_SIZE;
    }

    const minRowsPerPage: number = Math.min(...rowsPerPageOptions);
    return rowCount > minRowsPerPage;
};

export const displayTopToolbar = <T extends RowData>({
    columns,
    topToolbarCustomText,
    topToolbarActionsComponent,
    enableGlobalFilter,
}: {
    columns: Array<Column<T>>;
    topToolbarCustomText?: string;
    topToolbarActionsComponent?: ReactNode;
    enableGlobalFilter: boolean;
}): boolean =>
    topToolbarCustomText !== undefined ||
    topToolbarActionsComponent !== undefined ||
    enableGlobalFilter ||
    columns.some(
        (column: Column<T>): boolean => column.filterProps != null || column.visible === false,
    );

export const isTableEditable = <T extends RowData>(columns: Array<Column<T>>): boolean =>
    columns.some((column: Column<T>): boolean => column.editProps != null);

export const displayColumnFilters = <T extends RowData>(columns: Array<Column<T>>): boolean =>
    columns.some((column: Column<T>): boolean => column.filterProps != null);

export const getValueFromUpdater = <T>(updaterOrValue: Updater<T>, oldValue: T): T => {
    if (typeof updaterOrValue === "function") {
        return (updaterOrValue as (old: T) => T)(oldValue);
    }
    return updaterOrValue;
};

export const getRenderDetailPanel = <T extends RowData>(
    detailPanel?: (row: T) => ReactNode,
): ((params: { row: MRT_Row<T> }) => ReactNode) | undefined =>
    detailPanel == null
        ? undefined
        : ({ row }: { row: MRT_Row<T> }): ReactNode => detailPanel(row.original);

export const getSubRowsGetter = <T extends RowData>(
    enableExpanding?: boolean,
): ((row: T) => Array<T> | undefined) | undefined =>
    !enableExpanding
        ? undefined
        : (row: T): Array<T> | undefined => {
              if ("subRows" in row) {
                  return row.subRows;
              }

              return undefined;
          };

type ParseDataParams<T extends RowData> = {
    data: Array<T> | null;
    isSubRow?: boolean;
};

export const parseData = <T extends RowData>({
    data,
    isSubRow = false,
}: ParseDataParams<T>): Array<T> | null => {
    if (data == null) {
        return null;
    }

    const parsedData: Array<T> = data.map((row: T) => ({
        ...row,
        ...("subRows" in row &&
            row.subRows != null && {
                subRows: parseData({
                    data: row.subRows,
                    isSubRow: true,
                }),
            }),
        isSubRow,
    }));

    return parsedData;
};

export const tableStateToMrtTableState = <T extends RowData>({
    tableState,
    columnVisibility,
    showGlobalFilter,
}: {
    tableState?: TableState<T>;
    columnVisibility: MRT_VisibilityState;
    showGlobalFilter: boolean;
}): Partial<MRT_TableState> => {
    const baseState: Partial<MRT_TableState> = {
        columnVisibility,
        showGlobalFilter,
    };

    if (tableState == null) {
        return baseState;
    }

    const { sorting, filters, pagination } = tableState;

    const mrtSorting = sortingToMrtSorting(sorting);
    const mrtFilters = filtersToMrtFilters(filters);

    return {
        ...baseState,
        sorting: mrtSorting,
        columnFilters: mrtFilters,
        pagination: pagination ?? DEFAULT_PAGINATION,
    };
};

export const handleLocalPagination = <T extends RowData>(
    data: Array<T>,
    pagination: PaginationState,
): Array<T> => {
    const start: number = pagination.pageIndex * pagination.pageSize;
    const end: number = start + pagination.pageSize;

    return data.slice(start, end);
};
