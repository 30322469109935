import { ReactElement, ReactNode } from "react";
import { labelToDataId } from "@flexidao/ui-lib";
import { Button, Group, Modal as MantineModal, Text } from "@mantine/core";
import { getColor, getJsxFromContent } from "./utils";
import { DEFAULT_ACCEPT_TEXT, DEFAULT_CANCEL_TEXT, GREEN_COLOR, RED_COLOR } from "./consts";

type EditContractModalProps = {
    dataId: string;
    title: ReactNode;
    content: Array<ReactNode> | ReactNode;
    isOpen: boolean;
    rejectProps: {
        onReject: () => void;
        color?: "green" | "red";
        variant?: "outline" | "filled";
        displayCloseButton?: boolean;
        text?: string;
        hidden?: boolean;
    };
    acceptProps: {
        onAccept: () => void;
        color?: "green" | "red";
        variant?: "outline" | "filled";
        text?: string;
        hidden?: boolean;
    };
    trapFocus?: boolean;
    size?: "sm" | "md" | "lg" | "xl";
    hasHorizontalPadding?: boolean;
};

export const Modal = ({
    title,
    content,
    dataId,
    isOpen,
    rejectProps,
    acceptProps,
    trapFocus = true,
    size = "lg",
    hasHorizontalPadding = true,
}: EditContractModalProps): ReactElement => {
    return (
        <MantineModal
            data-id={dataId}
            centered
            size={size}
            styles={{
                body: {
                    paddingInline: hasHorizontalPadding ? "16px" : "0",
                },
            }}
            radius="md"
            trapFocus={trapFocus}
            opened={isOpen}
            onClose={rejectProps.onReject}
            withCloseButton={rejectProps.displayCloseButton ?? false}
            closeOnEscape
            closeOnClickOutside
            title={
                <Text
                    fz="xl"
                    fw="bold"
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "title",
                    })}
                >
                    {title}
                </Text>
            }
        >
            {getJsxFromContent(content)}

            {/* Buttons */}
            <Group position="right">
                {!rejectProps.hidden ? (
                    <Button
                        uppercase
                        variant={rejectProps.variant ?? "outline"}
                        color={rejectProps.color ? getColor(rejectProps.color) : RED_COLOR}
                        onClick={rejectProps.onReject}
                        data-id={labelToDataId({
                            prefix: dataId,
                            label: "cancel-button",
                        })}
                    >
                        {rejectProps.text ?? DEFAULT_CANCEL_TEXT}
                    </Button>
                ) : null}

                {!acceptProps.hidden ? (
                    <Button
                        uppercase
                        variant={acceptProps.variant ?? "filled"}
                        color={acceptProps.color ? getColor(acceptProps.color) : GREEN_COLOR}
                        onClick={acceptProps.onAccept}
                        data-id={labelToDataId({
                            prefix: dataId,
                            label: "accept-button",
                        })}
                    >
                        {acceptProps.text ?? DEFAULT_ACCEPT_TEXT}
                    </Button>
                ) : null}
            </Group>
        </MantineModal>
    );
};
