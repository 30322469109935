import {
    Column,
    FiltersState,
    PaginationState,
    RowActionColumnPosition,
    RowActionsRenderer,
    SortingState,
} from "@flexidao/ui-lib";
import { ReactNode } from "react";
import { ControlledFiltersProps, UnControlledFiltersProps } from "./filtering/types";
import { ControlledPaginationProps, UncontrolledPaginationProps } from "./pagination/types";
import { ControlledSortingProps, UnControlledSortingProps } from "./sorting/types";
import { RowSelectionState } from "@flexidao/ui-lib/components/data-display/table/row-selection/types";

export type Updater<T> = T | ((old: T) => T);
export type OnChangeFn<T> = (updaterOrValue: Updater<T>) => void;

export type RowData = Record<string, any>;

export enum DataControllerType {
    Controlled = "controlled",
    Uncontrolled = "uncontrolled",
}

export type TableState<T extends RowData> = {
    sorting?: SortingState<Omit<T, "rowExtraData">> | null;
    filters?: FiltersState<Omit<T, "rowExtraData">> | null;
    pagination?: PaginationState;
};

type RowActionsProps<T extends RowData> = {
    enableRowActions?: boolean;
    rowActionsCellRenderer?: RowActionsRenderer<T>;
    rowActionsColumnPosition?: RowActionColumnPosition;
};

type RowSelectionProps = {
    enableRowSelection?: boolean;
    rowSelectionState?: RowSelectionState;
    onRowSelectionChange?: (newRowSelection: RowSelectionState) => void;
    numSelectedRows?: number;
};

type BaseTableProps<T extends RowData> = {
    columns: Array<Column<T>>;
    data: Array<T> | null;
    rowCount: number;
    showLoadingOverlay?: boolean;
    showSkeletons?: boolean;
    detailPanel?: (row: T) => ReactNode;
    dataId?: string;
    initialState?: TableState<T>;
    enableTableFooter?: boolean;
    enableGlobalFilter?: boolean;
    enableExpanding?: boolean;
    actionsColumnTitle?: string;
    topToolbarActionsComponent?: ReactNode;
    enableCompactPagination?: boolean;
    topToolbarCustomText?: string;
} & RowActionsProps<T> &
    RowSelectionProps;

export type ControlledProps<
    T extends RowData,
    SortState extends Partial<T>,
    FilterState extends Partial<T>,
> = ControlledPaginationProps &
    ControlledSortingProps<SortState> &
    ControlledFiltersProps<FilterState>;
export type UncontrolledProps = UncontrolledPaginationProps &
    UnControlledSortingProps &
    UnControlledFiltersProps;

export type ControlledTableProps<
    T extends RowData,
    SortState extends Partial<T>,
    FilterState extends Partial<T>,
> = BaseTableProps<T> & {
    dataControllerType: DataControllerType.Controlled;
} & ControlledProps<T, SortState, FilterState>;
export type UncontrolledTableProps<T extends RowData> = BaseTableProps<T> & {
    dataControllerType: DataControllerType.Uncontrolled;
} & UncontrolledProps;

export type TableProps<
    T extends RowData,
    SortState extends Partial<T>,
    FilterState extends Partial<T>,
> = ControlledTableProps<T, SortState, FilterState> | UncontrolledTableProps<T>;
