import { ReactElement, ReactNode } from "react";
import { Group, Stack, Text } from "@mantine/core";
import { labelToDataId } from "@flexidao/ui-lib";
import { Breadcrumbs } from "./components/breadcrumbs";
import { Header } from "./components/header";
import { Filters } from "./components/filters";

type PageHeaderProps = {
    dataId: string;
    title?: string;
    description?: string;
    parents?: Array<{
        dataId?: string;
        title?: string;
        pathname: string;
        search?: string;
    }>;
    filters?: ReactElement | Array<ReactElement>;
    dataCompletenessChip?: ReactNode;
    cta?: ReactNode;
};

export const PageHeader = ({
    dataId,
    title,
    description,
    dataCompletenessChip,
    parents,
    filters,
    cta,
}: PageHeaderProps): ReactElement => (
    <Stack>
        <Group position="apart" align="flex-start" data-id={dataId}>
            <Stack spacing={0}>
                <Group spacing={8} align="center">
                    {parents ? (
                        <Breadcrumbs
                            dataId={labelToDataId({
                                prefix: dataId,
                                label: "breadcrumbs",
                            })}
                            currentPageTitle={title}
                            parents={parents}
                        />
                    ) : (
                        <Header
                            dataId={labelToDataId({
                                prefix: dataId,
                                label: "header",
                            })}
                            title={title}
                        />
                    )}

                    {dataCompletenessChip}
                </Group>

                {description ? (
                    <Text
                        component="p"
                        mt={0}
                        mb={0}
                        data-id={labelToDataId({
                            prefix: dataId,
                            label: "description",
                        })}
                    >
                        {description}
                    </Text>
                ) : null}
            </Stack>

            {cta}
        </Group>

        {filters ? (
            <Filters
                dataId={labelToDataId({
                    prefix: dataId,
                    label: "filters",
                })}
            >
                {filters}
            </Filters>
        ) : null}
    </Stack>
);
