import { ButtonVariants, flexidaoGrey } from "@flexidao/ui-lib";
import { Sx } from "@mantine/core";

type ButtonProps = {
    disabled: boolean;
    variant: ButtonVariants;
};

export const getButtonStyles = ({ disabled, variant }: ButtonProps): Sx => {
    if (disabled) {
        const commonStyles: Sx = {
            cursor: "not-allowed",
            color: flexidaoGrey[3],
        };

        switch (variant) {
            case ButtonVariants.Filled:
                return {};
            case ButtonVariants.Outline:
                return {
                    ...commonStyles,
                    backgroundColor: "transparent !important",
                    borderColor: `${flexidaoGrey[3]} !important`,
                };
            case ButtonVariants.Subtle:
                return {
                    ...commonStyles,
                    backgroundColor: "transparent !important",
                };
        }
    }

    return {};
};
