import { ComponentState } from "@flexidao/dto";
import {
    DonutChart,
    DonutChartCenterElement,
    DonutChartItem,
    IconWithTooltip,
    labelToDataId,
    TooltipType,
} from "@flexidao/ui-lib";
import { Group, Stack, Title } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionDonutChartProps = {
    label?: string;
    centerElement?: DonutChartCenterElement;
    donutChartItems: Array<DonutChartItem>;
    height?: number;
    thickness?: number;
    tooltip?: TooltipType;
    dataId?: string;
    state: ComponentState;
};

export const KpiSectionDonutChart = ({
    label,
    centerElement,
    tooltip,
    dataId,
    donutChartItems,
    height,
    thickness,
    state,
}: KpiSectionDonutChartProps): ReactElement => (
    <Stack spacing="4px" px="xl" py={0} data-id={dataId}>
        <Group spacing={4}>
            {label && (
                <Title order={2} weight={600} size="14px" lh="16px">
                    {label}
                </Title>
            )}
            {tooltip && (
                <IconWithTooltip
                    tooltip={tooltip}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "tooltip-content",
                    })}
                />
            )}
        </Group>

        <DonutChart
            dataId={labelToDataId({
                prefix: dataId,
                label: "donut-chart",
            })}
            data={donutChartItems}
            height={height}
            thickness={thickness}
            legendPosition="right"
            centerElement={centerElement}
            state={state}
        />
    </Stack>
);
