import {
    createEndTimeFromYear,
    getFirstDayOfCurrentYear as getFirstMonthOfCurrentYear,
    getFirstDayOfLastYear as getFirstMonthOfLastYear,
    getLastDayOfLastYear as getlastMonthOfLastYear,
} from "@flexidao/helpers";
import {
    getCurrentDate,
    getCurrentMonthLocal,
    getNextMonthLocal,
    getStartOfYearLocal,
} from "@flexidao/ui-lib/utils";
import { MonthRangeOption } from "./types";

export const fixMonth = <T extends Date | string | number>(_month: T): Date => {
    const month: Date = new Date(_month);
    month.setHours(0, 0, 0, 0);
    return month;
};

export const fixDateRange = <T extends Date | string | number>(
    _monthRange: [T, T],
): [Date, Date] => {
    return [fixMonth(_monthRange[0]), fixMonth(_monthRange[1])];
};

const getMinMonthInRange = (month: Date | null, maxRange: number): Date | null => {
    if (month == null) {
        return null;
    }

    // Clone the original date to avoid mutation
    const newDate = new Date(month);

    // Subtract 11 months to ensure a full 12-month range
    newDate.setMonth(newDate.getMonth() - (maxRange - 1));

    // Move to the start of the month
    newDate.setDate(1); // Ensure it always starts at the first day of the month

    return newDate;
};

const getMaxMonthInRange = (month: Date | null, maxDate: Date, maxRange: number): Date | null => {
    if (month == null) {
        return null;
    }

    // Clone the original date to avoid mutation
    const newDate = new Date(month);

    newDate.setMonth(newDate.getMonth() + maxRange);
    newDate.setDate(0); // Go back one day to get the last day of the current month

    // Ensure it doesn’t exceed maxDate, if provided
    if (maxDate && newDate.getTime() > maxDate.getTime()) {
        return maxDate;
    }

    return newDate;
};

export const getMinAndMaxMonthsFromPeriod = (
    [startMonthLocal, endMonthLocal]: [Date | null, Date | null],
    maxRangeInMonths: number,
): [Date | null, Date | null] => {
    const currentMonth: Date = getCurrentDate();

    const startMonthLocalMs: number | null =
        startMonthLocal != null ? startMonthLocal?.getTime() : null;
    const endMonthLocalMs: number | null = endMonthLocal != null ? endMonthLocal?.getTime() : null;

    // No Months have been selected
    if (startMonthLocalMs === null && endMonthLocalMs === null) {
        return [null, new Date()];
    }

    // Only start Month has been selected
    if (endMonthLocalMs === null) {
        const minMonth: Date | null = getMinMonthInRange(startMonthLocal, maxRangeInMonths);
        const maxMonth: Date | null = getMaxMonthInRange(
            startMonthLocal,
            currentMonth,
            maxRangeInMonths,
        );
        return [minMonth, maxMonth];
    }

    // Start and end Months have been selected
    return [null, currentMonth];
};

export const getShouldEnableButtons = (
    selectedMonthRange: [Date | null, Date | null],
    initialMonthRange: [Date, Date],
): {
    enableApplyButton: boolean;
    enableClearButton: boolean;
} => {
    const selectedStartMonthLocal: number | null = selectedMonthRange[0]?.getMonth() ?? null;
    const selectedEndMonthLocal: number | null = selectedMonthRange[1]?.getMonth() ?? null;
    const selectedStartYearLocal: number | null = selectedMonthRange[0]?.getFullYear() ?? null;
    const selectedEndYearLocal: number | null = selectedMonthRange[1]?.getFullYear() ?? null;

    const initialStartMonthLocal: number = initialMonthRange[0].getMonth();
    const initialEndMonthLocal: number = initialMonthRange[1].getMonth();
    const initialStartYearLocal: number = initialMonthRange[0].getFullYear();
    const initialEndYearLocal: number = initialMonthRange[1].getFullYear();

    const enableApplyButton: boolean =
        selectedStartMonthLocal != null &&
        selectedEndMonthLocal != null &&
        selectedStartYearLocal != null &&
        selectedEndYearLocal != null &&
        (selectedStartMonthLocal !== initialStartMonthLocal ||
            selectedEndMonthLocal !== initialEndMonthLocal ||
            selectedStartYearLocal !== initialStartYearLocal ||
            selectedEndYearLocal !== initialEndYearLocal);

    const enableClearButton: boolean =
        selectedStartMonthLocal != null && selectedEndMonthLocal != null;

    return { enableApplyButton, enableClearButton };
};

export const getMonthPeriodOptionLabel = (monthRangeOption: MonthRangeOption): string => {
    switch (monthRangeOption) {
        case MonthRangeOption.YearToDate:
            return "Year to Month";
        case MonthRangeOption.LastYear:
            return "Last year";
        case MonthRangeOption.Custom:
            return "Custom";
    }
};

export const getDatePeriodOptionFromMonthPeriod = (
    monthRange_: [Date | null, Date | null],
): MonthRangeOption => {
    if (monthRange_[0] === null || monthRange_[1] === null) {
        return MonthRangeOption.Custom;
    }

    const monthRange: [Date, Date] = fixDateRange([monthRange_[0], monthRange_[1]]);

    if (isMonthPeriodLastYear(monthRange)) {
        return MonthRangeOption.LastYear;
    }

    if (isMonthPeriodYearToMonth(monthRange)) {
        return MonthRangeOption.YearToDate;
    }

    return MonthRangeOption.Custom;
};

export const getDatePeriodFromMonthPeriodOption = (
    monthRangeOption: Exclude<MonthRangeOption, MonthRangeOption.Custom>,
): [Date, Date] => {
    switch (monthRangeOption) {
        case MonthRangeOption.YearToDate:
            return getDatePeriodForCurrentYear();
        case MonthRangeOption.LastYear:
            return getDatePeriodForLastYear();
    }
};

const getDatePeriodForCurrentYear = (): [Date, Date] => {
    const currentDate: Date = getCurrentDate();

    const firstDayOfCurrentYear: Date = getFirstMonthOfCurrentYear();

    return [firstDayOfCurrentYear, currentDate];
};

const getDatePeriodForLastYear = (): [Date, Date] => {
    const firstDayOfLastYear: Date = getFirstMonthOfLastYear();

    const lastDayOfLastYear: Date = getlastMonthOfLastYear();

    return [firstDayOfLastYear, lastDayOfLastYear];
};

const isMonthPeriodLastYear = (monthRange: [Date, Date]): boolean => {
    const firstDayOfLastYear: Date = getFirstMonthOfLastYear();

    const lastDayOfLastYear: Date = getlastMonthOfLastYear();

    return (
        monthRange[0].getMonth() === firstDayOfLastYear.getMonth() &&
        monthRange[0].getFullYear() === firstDayOfLastYear.getFullYear() &&
        monthRange[1].getMonth() === lastDayOfLastYear.getMonth() &&
        monthRange[1].getFullYear() === lastDayOfLastYear.getFullYear()
    );
};

export const getMonthPeriodForYear = (year: number): [Date, Date] => {
    const startMonthOfYear: Date = getStartOfYearLocal(year);
    const currentMonth = getCurrentMonthLocal();
    const nextMonth = getNextMonthLocal();
    const isPastYears = currentMonth.getUTCFullYear() !== year;
    // endMonthOfYear will be:
    // January next year [20YY-01, 20YY[+1]-01) if past years
    // Up to current month +1, if current year and there are months to come or last month: current month is Jan [20YY-01, 20YY-MM[+1])
    const endMonthOfYear: Date = isPastYears ? createEndTimeFromYear(year) : nextMonth;
    return [startMonthOfYear, endMonthOfYear];
};

const isMonthPeriodYearToMonth = (monthRange: [Date, Date]): boolean => {
    const firstDayOfCurrentYear: Date = getFirstMonthOfCurrentYear();

    const currentDay: Date = getCurrentDate();

    return (
        monthRange[0].getMonth() === firstDayOfCurrentYear.getMonth() &&
        monthRange[0].getFullYear() === firstDayOfCurrentYear.getFullYear() &&
        monthRange[1].getMonth() === currentDay.getMonth() &&
        monthRange[1].getFullYear() === currentDay.getFullYear()
    );
};
