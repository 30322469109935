import { type MantineThemeOverride, rem } from "@mantine/core";
import { colors } from "@flexidao/ui-lib/mantine-theme/colors/colors";
import { components } from "./components";
import {
    H1_FONT_SIZE_PX,
    H2_FONT_SIZE_PX,
    H3_FONT_SIZE_PX,
    H4_FONT_SIZE_PX,
} from "@flexidao/ui-lib/consts";
import { flexidaoGrey, flexidaoWhite } from "./colors";

export const mantineTheme: MantineThemeOverride = {
    // Defines color scheme for all components, defaults to "light"
    colorScheme: "light",

    // Controls focus ring styles:
    // auto – display focus ring only when user navigates with keyboard (default)
    // always – display focus ring when user navigates with keyboard and mouse
    // never – focus ring is always hidden (not recommended)
    focusRing: "auto",

    // Determines whether motion based animations should be disabled for
    // users who prefer to reduce motion in their OS settings
    respectReducedMotion: true,

    // Determines whether elements that do not have pointer cursor by default
    // (checkboxes, radio, native select) should have it
    cursorType: "pointer",

    // Default border-radius 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    defaultRadius: "sm",

    // White and black colors, defaults to '#fff' and '#000'
    // override from Adrià for black we use #333933 which is the text colour in the design
    // flexidaoBlack also added to colours for use in components
    white: flexidaoWhite,
    black: flexidaoGrey[9],

    // Object of arrays with 10 colors
    // imported from "./colours"
    colors: colors,

    // Key of theme.colors
    primaryColor: "flexidaoGreen",

    // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

    primaryShade: { light: 5, dark: 5 },

    // lets you override styles added to buttons with :active pseudo-class:
    activeStyles: { transform: "scale(0.95)" },

    // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
    //defaultGradient: { deg: number; from: MantineColor; to: MantineColor };

    //controls default loader that will be displayed by Loader and LoadingOverlay components:
    loader: "oval",

    // font-family and line-height used in most components
    fontFamily: "Roboto, sans-serif",
    lineHeight: 1.5,

    // Timing function used for animations, defaults to 'ease'
    //transitionTimingFunction: string,

    // Monospace font-family, used in Code, Kbd and Prism components
    fontFamilyMonospace: "Roboto Mono, monospace",

    // Sizes for corresponding properties
    //fontSizes: { xs: 8, sm: 12, md: 16, lg: 24, xl: 32 },
    //radius: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>,
    //spacing: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>,

    // Values used for box-shadow
    //shadows: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', string>,

    // Breakpoints used in some components to add responsive styles
    //breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>,

    // Styles added to buttons with `:active` pseudo-class
    //activeStyles: CSSObject,

    // h1-h6 styles, used in Title and TypographyStylesProvider components
    headings: {
        // fontFamily: CSSProperties['fontFamily'],
        // fontWeight: CSSProperties['fontWeight'],
        sizes: {
            // See heading options below
            h1: { fontSize: rem(H1_FONT_SIZE_PX) },
            h2: { fontSize: rem(H2_FONT_SIZE_PX) },
            h3: { fontSize: rem(H3_FONT_SIZE_PX) },
            h4: { fontSize: rem(H4_FONT_SIZE_PX) },
            // h5: Heading,
            // h6: Heading,
        },
    },

    // theme functions, see in theme functions guide
    //fn: MantineThemeFunctions,

    // Left to right or right to left direction, see RTL Support guide to learn more
    dir: "ltr",

    // defaultProps, styles and classNames for components
    components: components,

    // Global styles
    //globalStyles: (theme: MantineTheme) => CSSObject,

    // Add your own custom properties on Mantine theme
    //other: Record<string, any>,
};
