import { KpiSectionSpaceProps } from "@flexidao/ui-lib/components/data-display/kpi/kpi-sections/kpi-section-space";
import { KpiSectionCustomProps } from "./kpi-sections/kpi-section-custom";
import { KpiSectionDividerProps } from "./kpi-sections/kpi-section-divider";
import { KpiSectionLargeProps } from "./kpi-sections/kpi-section-large";
import { KpiSectionSmallProps } from "./kpi-sections/kpi-section-small";
import { KpiSectionTitleProps } from "./kpi-sections/kpi-section-title";
import { KpiSectionSmallWithPercentageProps } from "./kpi-sections/kpi-section-small-with-percentage";
import { KpiSectionDonutChartProps } from "./kpi-sections/kpi-section-donut-chart";

export enum KpiSectionType {
    Small = "small",
    SmallWithPercentage = "small-with-percentage",
    Large = "large",
    DonutChart = "donut-chart",
    Divider = "divider",
    Space = "space",
    Custom = "custom",
    Title = "title",
}

type KpiSectionSmall = { sectionType: KpiSectionType.Small } & KpiSectionSmallProps;
type KpiSectionSmallWithPercentage = {
    sectionType: KpiSectionType.SmallWithPercentage;
} & KpiSectionSmallWithPercentageProps;
type KpiSectionLarge = { sectionType: KpiSectionType.Large } & KpiSectionLargeProps;
type KpiSectionDonutChart = { sectionType: KpiSectionType.DonutChart } & KpiSectionDonutChartProps;
type KpiSectionCustom = { sectionType: KpiSectionType.Custom } & KpiSectionCustomProps;
type KpiSectionDivider = { sectionType: KpiSectionType.Divider } & KpiSectionDividerProps;
type KpiSectionSpace = { sectionType: KpiSectionType.Space } & KpiSectionSpaceProps;
type KpiSectionTitle = { sectionType: KpiSectionType.Title } & KpiSectionTitleProps;

export type KpiSection =
    | KpiSectionTitle
    | KpiSectionSmall
    | KpiSectionSmallWithPercentage
    | KpiSectionLarge
    | KpiSectionDonutChart
    | KpiSectionCustom
    | KpiSectionDivider
    | KpiSectionSpace;
