import { Group, Stack } from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { fixMonth, getMinAndMaxMonthsFromPeriod } from "../utils";

type MonthRangeCalendarProps = {
    selectedMonthRange: [Date | null, Date | null];
    maxRangeInMonths: number;
    handleSelectedMonthRangeChange: (value: [Date | null, Date | null]) => void;
};

export const MonthRangeCalendar = ({
    selectedMonthRange,
    maxRangeInMonths,
    handleSelectedMonthRangeChange,
}: MonthRangeCalendarProps): ReactElement => {
    const initialMonth: Date | null = selectedMonthRange[1] ?? selectedMonthRange[0];

    const [month, setMonth] = useState<Date | null>(initialMonth);

    const [minMonth, maxMonth] = useMemo(
        () => getMinAndMaxMonthsFromPeriod(selectedMonthRange, maxRangeInMonths),
        [selectedMonthRange, maxRangeInMonths],
    );

    const handleOnChange = (newMonthRange: [Date | null, Date | null]): void => {
        const fixedStartTime: Date | null = newMonthRange[0] ? fixMonth(newMonthRange[0]) : null;
        const fixedEndTime: Date | null = newMonthRange[1] ? fixMonth(newMonthRange[1]) : null;

        const newMonth: Date | null = fixedEndTime ?? fixedStartTime;

        handleSelectedMonthRangeChange([fixedStartTime, fixedEndTime]);
        setMonth(newMonth);
    };

    useEffect(() => {
        setMonth(initialMonth);
    }, [initialMonth]);

    return (
        <Stack p="md">
            <Group position="center" align="baseline">
                <Stack spacing="4px" data-id="month-range-picker--calendar">
                    <MonthPicker
                        type="range"
                        allowSingleDateInRange
                        minDate={minMonth ?? undefined}
                        maxDate={maxMonth ?? undefined}
                        value={selectedMonthRange}
                        onChange={handleOnChange}
                        date={month ?? undefined}
                        onDateChange={setMonth}
                    />
                </Stack>
            </Group>
        </Stack>
    );
};
