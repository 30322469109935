import { FetchError, isFetchError } from "@flexidao/api-client";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement, ReactNode } from "react";
import { isJSXElement } from "../utils";
import { QueryWrapperSkeletonArgs } from "./types";
import { NotificationVariant, showNotification } from "@flexidao/ui-lib/components";

type TableQueryWrapperProps<T> = {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: {
        id?: string;
        title?: ReactNode;
        message?: ReactNode;
    };
    skeleton: ReactElement | ((args: QueryWrapperSkeletonArgs) => ReactElement);
    content: (args: { data: T; showLoadingOverlay: boolean }) => ReactElement;
    error: ReactElement;
};

export const TableQueryWrapper = <T>({
    content,
    error,
    query,
    skeleton,
    errorNotification,
}: TableQueryWrapperProps<T>): ReactElement => {
    if (skeleton && query.isLoading) {
        if (isJSXElement(skeleton)) {
            return skeleton;
        }
        return skeleton(query);
    }

    if (
        query.isError ||
        isFetchError(query.data) ||
        query.data === undefined ||
        query.data === null
    ) {
        console.error("Query error", query.data);
        if (errorNotification) {
            showNotification({
                variant: NotificationVariant.Error,
                id: errorNotification.id,
                title: errorNotification.title,
                message: errorNotification.message,
            });
        }
        return error;
    }

    return content({
        data: query.data,
        showLoadingOverlay: query.isFetching && query.isPreviousData,
    });
};
