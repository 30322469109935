import { Button as MantineButton } from "@mantine/core";
import { ReactElement } from "react";
import { ButtonColors, ButtonVariants } from "./types";
import { getButtonStyles } from "./utils";

type ButtonProps = {
    children: ReactElement | string;
    dataId: string;
    onClick?: () => void;
    variant?: ButtonVariants;
    color?: ButtonColors;
    disabled?: boolean;
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    type?: "submit" | "button";
};

export const Button = ({
    children,
    dataId,
    onClick,
    leftIcon,
    rightIcon,
    color = ButtonColors.Green,
    variant = ButtonVariants.Filled,
    disabled = false,
    type = "button",
}: ButtonProps): ReactElement => (
    <MantineButton
        uppercase
        data-id={dataId}
        color={color}
        disabled={disabled}
        variant={variant}
        onClick={onClick}
        sx={getButtonStyles({
            disabled,
            variant,
        })}
        compact={variant === ButtonVariants.Subtle}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        type={type}
    >
        {children}
    </MantineButton>
);
