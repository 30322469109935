import { NotificationVariant } from "./types";

export const getColorFromNotificationVariant = (
    notificationVariant: NotificationVariant,
): "flexidaoGreen" | "flexidaoRed" => {
    switch (notificationVariant) {
        case NotificationVariant.Success:
            return "flexidaoGreen";
        case NotificationVariant.Error:
            return "flexidaoRed";
    }
};
