import { SegmentedControl, SegmentedControlItem, Stack, Text } from "@mantine/core";
import { ReactElement, useMemo } from "react";
import { MonthRangeOption } from "../types";
import { getMonthPeriodOptionLabel } from "../utils";

type MonthRangePresetsProps = {
    selectedMonthRange: [Date | null, Date | null];
    monthRangeOptions: Array<MonthRangeOption>;
    selectedMonthRangeOption: MonthRangeOption;
    handleMonthRangeOptionSelected: (MonthRangeOption: MonthRangeOption) => void;
};

export const MonthRangePresets = ({
    selectedMonthRange,
    monthRangeOptions,
    selectedMonthRangeOption,
    handleMonthRangeOptionSelected,
}: MonthRangePresetsProps): ReactElement => {
    // If both months are defined or both months are null, the month range picker is enabled
    const areMonthRangeOptionsEnabled: boolean =
        (selectedMonthRange[0] !== null && selectedMonthRange[1] !== null) ||
        (selectedMonthRange[0] === null && selectedMonthRange[1] === null);

    const monthRangeOptionList: Array<SegmentedControlItem> = useMemo(
        () => [
            ...monthRangeOptions.map((MonthRangeOption: MonthRangeOption) => ({
                value: MonthRangeOption,
                label: getMonthPeriodOptionLabel(MonthRangeOption),
            })),
        ],
        [monthRangeOptions],
    );

    const value = useMemo(
        () =>
            selectedMonthRangeOption === MonthRangeOption.Custom ? null : selectedMonthRangeOption,
        [selectedMonthRangeOption],
    );

    return (
        <Stack p="16px" spacing="8px">
            <Text size="14px" weight={600}>
                Presets
            </Text>

            <SegmentedControl
                data-id="month-range-picker--presets"
                name="month-range-picker--presets"
                orientation="vertical"
                color="flexidaoGreen"
                disabled={!areMonthRangeOptionsEnabled}
                data={monthRangeOptionList}
                value={value!}
                onChange={handleMonthRangeOptionSelected}
            />
        </Stack>
    );
};
