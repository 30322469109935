import { formatNumber } from "@flexidao/ui-lib";
import { Group, Skeleton, Text } from "@mantine/core";
import { ReactElement } from "react";
import { ComponentState, Units } from "@flexidao/dto";
import { SKELETON_SMALL_VALUE_DEFAULT_WIDTH } from "./consts";

export type KpiSectionSmallProps = {
    label: string;
    value: number | string | null;
    numDecimals: number;
    unit: string | null;
    dataId?: string;
    color?: string;
    showSign?: boolean;
    showSkeleton?: boolean; // This will be removed later on
    state?: ComponentState; // This will be made mandatory later on
};

export const KpiSectionSmall = ({
    label,
    value,
    numDecimals,
    unit,
    color,
    dataId,
    showSign,
    showSkeleton,
    state,
}: KpiSectionSmallProps): ReactElement => (
    <Group position="apart" align="flex-start" px="xl" py={8} mih={40} data-id={dataId}>
        <Text size="sm" weight={600}>
            {label}
        </Text>

        {getDisplayValue({ showSkeleton, state, value, showSign, numDecimals, unit, color })}
    </Group>
);

export const getDisplayValue = ({
    showSkeleton,
    state,
    value,
    showSign,
    numDecimals,
    unit,
    color,
    width,
}: {
    showSkeleton?: boolean;
    state?: ComponentState;
    value: number | string | null;
    showSign?: boolean;
    numDecimals: number;
    unit: string | null;
    color?: string;
    width?: number;
}): ReactElement => {
    if (showSkeleton || state === ComponentState.Loading) {
        return <Skeleton height={21} width={width ?? SKELETON_SMALL_VALUE_DEFAULT_WIDTH} />;
    }

    if (state === ComponentState.Error) {
        return (
            <Text size="xs" color="flexidaoRed" ta="right">
                Error
            </Text>
        );
    }

    if (typeof value === "string") {
        return (
            <Text size="sm" weight={600} ta="right">
                {value}
            </Text>
        );
    }

    const valueString: string = formatNumber(value, { numDecimals, showSign });
    const valueSplitArray: Array<string> = valueString.split(".");

    const integerPart: string = valueSplitArray[0]!;
    const decimalPart: string | null = valueSplitArray[1] ?? null;

    return (
        <Text
            size="sm"
            weight={600}
            w={width}
            miw={width}
            ta="right"
            sx={{
                whiteSpace: "nowrap",
            }}
        >
            <Text span>
                <Text span size="14px" color={color}>
                    {integerPart}
                </Text>

                {decimalPart !== null && (
                    <Text span size="12px" color={color}>
                        .{decimalPart}
                    </Text>
                )}
            </Text>

            {value !== null && unit !== null && (
                <>
                    {unit === Units.Percent ? "" : "\u00A0"}
                    <Text span weight="bold" size="12px" color={color}>
                        {unit}
                    </Text>
                </>
            )}
        </Text>
    );
};
