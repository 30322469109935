import { ReactElement, ReactNode } from "react";
import { ActionIcon, Collapse, Group, List, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp, IconSparkles } from "@tabler/icons-react";
import { flexidaoYellow } from "@flexidao/ui-lib";

type InsightProps = {
    insights: Array<ReactNode>;
    dataId: string;
};

/**
 * Insight component with aligned header and content
 *
 * @param {Array<ReactNode>} insights - The insights we want to render
 * @param {string} dataId - The dataId of the insights component
 *
 * @returns {ReactElement} - The insight component
 */
export const Insight = ({ insights, dataId }: InsightProps): ReactElement => {
    const [opened, { toggle }] = useDisclosure(false);

    if (insights.length === 0) {
        return <></>;
    }

    return (
        <Paper bg={flexidaoYellow[0]} data-id={dataId} p="md">
            <Stack spacing="8px">
                <Group position="apart" align="center" noWrap>
                    <Group spacing="8px" align="center" noWrap>
                        <IconSparkles size={20} color={flexidaoYellow[5]} />
                        <Text size="sm" weight="bold">
                            Insights
                        </Text>
                    </Group>

                    <ActionIcon onClick={toggle} variant="filled" color="flexidaoYellow">
                        {opened ? <IconChevronUp /> : <IconChevronDown />}
                    </ActionIcon>
                </Group>

                <Stack spacing="sm" ml={28}>
                    {/* 28px = icon size (20px) + Group spacing (8px) */}
                    {!opened ? (
                        <Text>Expand to see all the available insights in this page</Text>
                    ) : null}

                    <Collapse in={opened}>
                        {insights.length === 1 ? (
                            insights[0]
                        ) : (
                            <List type="ordered" spacing="16px">
                                {insights.map((insight, i) => (
                                    <List.Item key={i} pr="24px">
                                        {insight}
                                    </List.Item>
                                ))}
                            </List>
                        )}
                    </Collapse>
                </Stack>
            </Stack>
        </Paper>
    );
};
