import { ReactElement, ReactNode } from "react";
import { Container, Stack } from "@mantine/core";
import { PAGE_MARGIN_BOTTOM } from "./consts";

type PageProps = {
    dataId: string;
    children: ReactNode;
};

export const Page = ({ dataId, children }: PageProps): ReactElement => (
    <Container size="xl" mt={0} mb={PAGE_MARGIN_BOTTOM} p={0} data-id={dataId} fluid>
        <Stack spacing="8px">{children}</Stack>
    </Container>
);
