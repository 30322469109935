import { Group, Skeleton, Text } from "@mantine/core";
import { ReactElement } from "react";
import { ComponentState } from "@flexidao/dto";
import { PERCENTAGE_SMALL_VALUE_DEFAULT_WIDTH } from "./consts";
import { getDisplayValue } from "./kpi-section-small";

export type KpiSectionSmallWithPercentageProps = {
    label: string;
    value: {
        value: number | null;
        numDecimals: number;
        unit: string | null;
        width?: number;
        color?: string;
        showSign?: boolean;
    };
    percentage: {
        value: number | null;
        numDecimals: number;
        unit: string | null;
        width?: number;
        color?: string;
        showSign?: boolean;
    };
    dataId: string;
    state: ComponentState;
    color?: string;
};

export const KpiSectionSmallWithPercentage = ({
    label,
    value,
    percentage,
    dataId,
    state,
    color,
}: KpiSectionSmallWithPercentageProps): ReactElement => (
    <Group
        position="apart"
        align="flex-start"
        px="xl"
        py={8}
        mih={40}
        data-id={dataId}
        spacing="8px"
        noWrap
    >
        {!label ? (
            <Skeleton height={21} width={100} />
        ) : (
            <Text size="sm" weight={600}>
                {label}
            </Text>
        )}

        <Group spacing="8px" align="flex-start" noWrap>
            {getDisplayValue({
                state,
                color,
                ...value,
            })}

            {getDisplayValue({
                state,
                color,
                ...percentage,
                width: percentage.width ?? PERCENTAGE_SMALL_VALUE_DEFAULT_WIDTH,
            })}
        </Group>
    </Group>
);
