import { ReactElement } from "react";
import { EmptyCell } from "./empty-cell";
import { Anchor, LinkColumnType } from "@flexidao/ui-lib";
import { Link } from "react-router-dom";

type LinkCellProps = {
    value: LinkColumnType;
    isSubRow?: boolean;
};

export const LinkCell = ({ value, isSubRow = false }: LinkCellProps): ReactElement => {
    if (value.value == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    return (
        <Anchor
            component={Link}
            target={value.newTab ? "_blank" : "_self"}
            rel="noopener noreferrer"
            to={value.to}
        >
            {value.value}
        </Anchor>
    );
};
