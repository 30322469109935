import { Result } from "@flexidao/helpers";
import { AxiosError } from "axios";
import * as D from "schemawax";

type WorkflowError<T extends string, S> = {
    kind: T;
    path: string;
    payload: S;
};
export type FetchError =
    | WorkflowError<
          "decode_error",
          D.DecoderError & {
              data: unknown;
          }
      >
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | WorkflowError<"http_error", AxiosError<unknown, any>>
    | WorkflowError<"unhandled_error", Error>;

export const isFetchError = (response: unknown): response is FetchError => {
    return response !== null && typeof response === "object" && "kind" in response;
};

export const throwIfFetchError = <T>(payload: T | FetchError): T => {
    if (isFetchError(payload)) {
        throw payload;
    }

    return payload;
};

export type ApiResultResponse<ApiResult> = Promise<Result<ApiResult, FetchError>>;
export type ApiResponse<ApiResult> = Promise<ApiResult | FetchError>;
export type OverloadApiResponse<ApiResult> = Promise<
    ApiResult | FetchError | Result<ApiResult, FetchError>
>;
export type DeletePayload = { success: boolean; error?: string };

export type DeleteApiResponse = ApiResponse<DeletePayload>;

export type ApiClientConfig = {
    useMockData: boolean;
    useResult: boolean;
};
