import { TabVariant } from "@flexidao/ui-lib";
import { MantineColor } from "@mantine/core";

export const getStylesFromTabVariant = (
    tabVariant: TabVariant,
): {
    color: MantineColor | undefined;
    backgroundColor: MantineColor;
} => {
    switch (tabVariant) {
        case TabVariant.Colored:
            return {
                color: "flexidaoGreen",
                backgroundColor: "white",
            };
        case TabVariant.Neutral:
            return {
                color: undefined,
                backgroundColor: "flexidaoGrey.1",
            };
    }
};
