import { ReactNode } from "react";
import { notifications } from "@mantine/notifications";
import { NotificationVariant } from "./types";
import { getColorFromNotificationVariant } from "./utils";

type NotificationProps = {
    variant: NotificationVariant;
    id?: string;
    title?: ReactNode;
    message: ReactNode;
};

export const showNotification = ({ variant, id, title, message }: NotificationProps): void => {
    notifications.show({
        id,
        title,
        message,
        color: getColorFromNotificationVariant(variant),
    });
};
