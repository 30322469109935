import { MantineThemeOverride } from "@mantine/core";
import {
    anchorDefaultProps,
    containerDefaultProps,
    dividerDefaultProps,
    multiSelectDefaultProps,
    paperDefaultProps,
    selectDefaultProps,
} from "./";

export const components: MantineThemeOverride["components"] = {
    Container: {
        defaultProps: containerDefaultProps,
    },
    Anchor: {
        defaultProps: anchorDefaultProps,
    },
    Divider: {
        defaultProps: dividerDefaultProps,
    },
    Paper: {
        defaultProps: paperDefaultProps,
    },
    MultiSelect: {
        defaultProps: multiSelectDefaultProps,
    },
    Select: {
        defaultProps: selectDefaultProps,
    },
};
